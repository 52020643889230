import React, { useContext } from "react";
import "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import "../../scss/AdminDashboard.scss";
import { ProductContext } from "../../context/ProductContext";

const AdminDashboard = () => {
  const { orders } = useContext(ProductContext);

  const data = {
    labels: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Salse 2020",
        data: [2, 9, 3, 5, 2, 3, 5, 6, 2, 11, 7, 10],
        fill: true,
        borderColor: ["rgba(255, 206, 86, 0.5)"],
        backgroundColor: ["rgba(255, 206, 86, 0.5)"],
        pointBackgroundColor: "rgba(255, 206, 86, 0.5)",
        pointBorderColor: "rgba(255, 206, 86, 0.5)",
        borderWidth: 0,
      },
      {
        label: "Salse 2021",
        data: [3, 6, 2, 1, 7, 5, 5, 4, 3, 7, 5, 7],
        fill: true,
        borderColor: ["rgba(54,162,235,0.5)"],
        backgroundColor: ["rgba(54,162,235,0.5)"],
        pointBackgroundColor: "rgba(54,162,235,0.5)",
        pointBorderColor: "rgba(54,162,235,0.5)",
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 12,
        stepSize: 0.5,
        title: {
          display: true,
          text: "Line Chat",
        },
      },
    },
  };

  let totals = [];
  orders.map((order) => {
    let startDate = new Date("2021-01-01");
    let EndDate = new Date("2021-12-31");
    let date = new Date(order.BookingDate);
    if (
      startDate.getTime() <= date.getTime() &&
      EndDate.getTime() >= date.getTime()
    ) {
      totals.push(order.Total_KM);
    }
    return order;
  });

  // console.log(totals);

  const data1 = {
    labels: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Sales",
        data: totals,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options1 = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };
  return (
    <div className="adminContainer">
      {/* <div className="menuList">
        <Link to="/admin/dashboard/partners">PARTNERS</Link>
        <Link to="/admin/dashboard/clients">CLIENTS</Link>
        <Link to="/">ORDERS</Link>
        <Link to="/">PACKAGES</Link>
        <Link to="/">SERVICES</Link>
        <Link to="/">ACCOUNTS</Link>
        <span>
          <ExitToAppIcon onClick={logout} />
        </span>
      </div> */}
      <h1>Admin DashBoard </h1>
      <div className="chart">
        <Line data={data} options={options} />
        <Bar data={data1} options={options1} />
      </div>
    </div>
  );
};

export default AdminDashboard;
