//
import React, { useContext, useState, useEffect } from "react";
import { ClientContext } from "../../context/ClientContext";
import { ProductContext } from "../../context/ProductContext";
import { getClientOrders } from "../../helpers/ClientApi";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useNavigate } from "react-router-dom";
import "../../scss/ClientDashboard.scss";

const ClientDashboard = () => {
  const navigate = useNavigate();
  // const history = useHistory();
  const [pTable, setPTable] = useState(false);
  const [aTable, setATable] = useState(false);
  const [cTable, setCTable] = useState(false);

  const { client } = useContext(ClientContext);
  const { clientOrders, setClientOrders } = useContext(ProductContext);

  useEffect(() => {
    const getData = async () => {
      let items = await getClientOrders();
      setClientOrders(items);
    };
    getData();
  }, [setClientOrders]);

  let pendings = [];
  let accepts = [];
  let completes = [];
  clientOrders?.forEach((item) => {
    if (item.BookingStatus === "Pending") pendings.push(item);
    if (item.BookingStatus === "Confirmed") accepts.push(item);
    if (item.BookingStatus === "Accomplished") completes.push(item);
  });

  const goToPendingJob = () => {
    setPTable(true);
    setATable(false);
    setCTable(false);
  };
  const goToAcceptJob = () => {
    setPTable(false);
    setATable(true);
    setCTable(false);
  };
  const goToCompleteJob = () => {
    setPTable(false);
    setATable(false);
    setCTable(true);
  };

  const goTo = (data) => {
    navigate(`/print/${data}`);
  };
  const update = (data) => {
    // console.log(data);
    navigate(`/client-jobboard/${data}`);
  };
  const goToProfile = () => {
    navigate(`/client-profile/${client._id}`);
  };

  // console.log(client);
  // 61f4f8bc0239bf1aac32a926

  return (
    <div className="client-dashboard">
      <div className="heading">Client Dashboard</div>
      <div className="body">
        <div className="profile">
          <img src={client?.Avatar.url} alt={client.FullName} />
          <div className="body"></div>
          <h4 style={{ fontSize: "1.5vmax" }}>Name: {client.FullName}</h4>
          <h4>
            Email : <span>{client.Email}</span>
          </h4>
          <h4>
            Tel : <span>{client.Phone}</span>
          </h4>
          <h4>
            Joined on : <span>{String(client.createdAt).slice(0, 10)}</span>
          </h4>
          <div className="btn">
            <button
              type="submit"
              className="button-common"
              onClick={goToProfile}
            >
              DETAILS INFO
            </button>
          </div>
        </div>
        <div className="client-orders">
          <div className="heading">My Orders</div>
          <div className="client-orders-buttons">
            <button
              type="submit"
              className="button-common"
              onClick={goToPendingJob}
            >
              PENDING --&gt; {pendings.length}
            </button>
            <button
              type="submit"
              className="button-common"
              onClick={goToAcceptJob}
            >
              ACCEPT --&gt; {accepts.length}
            </button>
            <button
              type="submit"
              className="button-common"
              onClick={goToCompleteJob}
            >
              COMPLETE --&gt; {completes.length}
            </button>
          </div>
        </div>
      </div>
      <div className="client-order-tables">
        {pendings.length > 0 && pTable && (
          <Table>
            <Thead>
              <Tr>
                {/* <Th>Order ID</Th> */}
                <Th>Package</Th>
                <Th>Date</Th>
                <Th>Time</Th>
                <Th>Total</Th>
                <Th>Update</Th>
              </Tr>
            </Thead>
            {pendings.map((item) => {
              const date = new Date(item.BookingDate);
              const localDate = date.toLocaleDateString();
              return (
                <Tbody key={item._id}>
                  <Tr>
                    {/* <Td>{item.OrderNumber}</Td> */}
                    <Td>{item.PackageId?.Title}</Td>
                    <Td>{localDate}</Td>
                    <Td>{item.BookingTime}</Td>
                    <Td>
                      {(
                        item.PackageId.PackagePrice +
                        item.Total_KM * item.PackageId.PricePerKm +
                        item.StopagesAddress.length *
                          item.PackageId.StoppagePrice +
                        ((item.PackageId.PackagePrice +
                          item.Total_KM * item.PackageId.PricePerKm +
                          item.StopagesAddress.length *
                            item.PackageId.StoppagePrice) *
                          item.PackageId.MwSt) /
                          100
                      ).toFixed(2) * 1}{" "}
                      £
                    </Td>
                    <Td>
                      <button onClick={() => update(item._id)} type="button">
                        EDIT
                      </button>
                    </Td>
                  </Tr>
                </Tbody>
              );
            })}
          </Table>
        )}
        {accepts.length > 0 && aTable && (
          <Table>
            <caption>
              In order to update any info, Please call -{" "}
              <a href="tel:+7769372911">0000000000</a>.
            </caption>
            <Thead>
              <Tr>
                {/* <Th>Order ID</Th> */}
                <Th>Package</Th>
                <Th>Date</Th>
                <Th>Time</Th>
                <Th>Service Partner</Th>
                <Th>Estimate Total</Th>
              </Tr>
            </Thead>
            {accepts.map((item) => {
              const date = new Date(item.BookingDate);
              const localDate = date.toLocaleDateString();
              return (
                <Tbody key={item._id}>
                  <Tr>
                    {/* <Td>{item.OrderNumber}</Td> */}
                    <Td>{item.PackageId?.Title}</Td>
                    <Td>{localDate}</Td>
                    <Td>{item.BookingTime}</Td>
                    <Td>
                      {item.PartnerId?.FullName} <br />{" "}
                      <a href="tel:+{item.PartnerId.MobileNumber}">
                        {item.PartnerId?.MobileNumber}
                      </a>
                    </Td>
                    <Td>
                      {(
                        item.PackageId.PackagePrice +
                        item.Total_KM * item.PackageId.PricePerKm +
                        item.StopagesAddress.length *
                          item.PackageId.StoppagePrice +
                        ((item.PackageId.PackagePrice +
                          item.Total_KM * item.PackageId.PricePerKm +
                          item.StopagesAddress.length *
                            item.PackageId.StoppagePrice) *
                          item.PackageId.MwSt) /
                          100
                      ).toFixed(2) * 1}{" "}
                      £
                    </Td>
                  </Tr>
                </Tbody>
              );
            })}
          </Table>
        )}
        {completes.length > 0 && cTable && (
          <Table>
            <Thead>
              <Tr>
                {/* <Th>Order ID</Th> */}
                <Th>Package</Th>
                <Th>Date</Th>
                <Th>Time</Th>
                <Th>Total</Th>
                <Th>Print</Th>
              </Tr>
            </Thead>
            {completes.map((item) => {
              const date = new Date(item.BookingDate);
              const localDate = date.toLocaleDateString();
              return (
                <Tbody key={item._id}>
                  <Tr>
                    {/* <Td>{item.OrderNumber}</Td> */}
                    <Td>{item.PackageId?.Title}</Td>
                    <Td>{localDate}</Td>
                    <Td>{item.BookingTime}</Td>
                    <Td>
                      {(
                        item.PackageId.PackagePrice +
                        item.Total_KM * item.PackageId.PricePerKm +
                        item.StopagesAddress.length *
                          item.PackageId.StoppagePrice +
                        ((item.PackageId.PackagePrice +
                          item.Total_KM * item.PackageId.PricePerKm +
                          item.StopagesAddress.length *
                            item.PackageId.StoppagePrice) *
                          item.PackageId.MwSt) /
                          100
                      ).toFixed(2) * 1}{" "}
                      £
                    </Td>
                    <Td>
                      <button onClick={() => goTo(item._id)} type="button">
                        VIEW
                      </button>
                    </Td>
                  </Tr>
                </Tbody>
              );
            })}
          </Table>
        )}
      </div>
    </div>
  );
};

export default ClientDashboard;
