import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { ProductContext } from "../context/ProductContext";
import { getService } from "../helpers/PackageApi";
import '../scss/Service.scss'

const Service = () => {
  const params = useParams();
  const { service, setService, loading, setLoading } =
    useContext(ProductContext);

  useEffect(() => {
    const get = async () => {
      const result = await getService(params.id);
      setService(result);
      setLoading(false);
    };
    get();
  }, [params.id, setService, setLoading]);

  if (loading === true) <Loading />;

  return (
    <div className="serviceContainer">
      <img src={service.BigImage?.url} alt="" />
      <h1>{service.Title}</h1>
      <p dangerouslySetInnerHTML={{ __html: service.BigDetails }}/>
    </div>
  );
};

export default Service;
