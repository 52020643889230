import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_API_BASE_URL || `http://localhost:5000`;
axios.defaults.withCredentials = true;

const extractApiError = (errAxios) => {
  return errAxios.response
    ? errAxios.response.data
    : { error: { message: "API not reachable" } };
};

export const authenticatePartner = async () => {
  try {
    const response = await axios.post(`/partners/auth`);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const getPartners = async () => {
  try {
    const response = await axios.get(`/partners/admin/partners`);
    return response.data;
  } catch (error) {
    // console.log(error);
    return extractApiError(error);
  }
};
export const getPartnerDetails = async (id) => {
  try {
    const response = await axios.get(`/partners/${id}`);
    return response.data;
  } catch (error) {
    // console.log(error);
    return extractApiError(error);
  }
};

export const login = async (data) => {
  // console.log(data);
  try {
    const response = await axios.post(`/partners/login`, data);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const googleLogin = async (data) => {
  try {
    const response = await axios.post(`/partners/googleLogin`, data);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const register = async (data) => {
  // console.log("p api 61------",data);
  try {
    const response = await axios.post(`/partners/register`, data);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const updatePartner = async (id, data) => {
  try {
    const response = await axios.patch(`/partners/${id}`, data);
    return response.data;
  } catch (error) {}
};

export const logOut = async () => {
  try {
    const response = await axios.get("/partners/logout");
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const getPartnerOrders = async () => {
  try {
    const response = await axios.get(`/partners/orders`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addPLogin = async (data) => {
  console.log(data);
};
