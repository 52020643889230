import React, { useContext, useState, useEffect } from "react";
import { addPLogin, getPartners } from "../../helpers/PartnerApi";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { PartnerContext } from "../../context/PartnerContext";
import "../../scss/Partners.scss";

const Partners = () => {
  const [search, setSearch] = useState();
  const [sort, setSort] = useState();
  const { setPartner, partners, setPartners, setLoading, pCallback } =
    useContext(PartnerContext);

  useEffect(() => {
    const getAllPartners = async () => {
      try {
        const result = await getPartners();
        if (result.error) {
          setPartners();
          setLoading(false);
          return;
        }
        setPartners(result);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getAllPartners();
  }, [setPartners, pCallback, setLoading]);

  // console.log(partners);

  const login = async (data) => {
    let part = await addPLogin(data);
    setPartner(part);
    window.open("/partner-dashboard", "_blank");
  };
  // console.log(partners);
  return (
    <div className="admin-partner-areas">
      <h1>Kurier 247 Partner List</h1>
      <div className="search-filter">
        <div className="text-input">
          <input
            type="text"
            value={search}
            placeholder="Enter your search!"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
          <select value={sort} onChange={(e) => setSort(e.target.value)}>
            <option value="All">Select Type</option>
            <option value="SelfEmployed">SelfEmployed</option>
            <option value="Worker">Worker</option>
          </select>
          <select value={sort} onChange={(e) => setSort(e.target.value)}>
            <option value="Active">Active</option>
            <option value="Pending">Pending</option>
            <option value="OnHold">OnHold</option>
            <option value="Suspend">Suspend</option>
          </select>
        </div>
        {/* <div className="submit-button">
          <button type="submit">Search</button>
        </div> */}
      </div>

      <Table>
        <Thead>
          <Tr>
            <Th>Partner ID</Th>
            <Th>Partner Role</Th>
            <Th>Partner Name</Th>
            <Th>Status</Th>
            <Th>RegisterAs</Th>
            <Th>Nationality</Th>
            <Th>TaxId</Th>
            <Th>Mobile Number</Th>
            <Th>Email</Th>
            <Th>Commission</Th>
            <Th>IBAN</Th>
            <Th>Details</Th>
            {/* <Th>Login</Th> */}
          </Tr>
        </Thead>
        {partners?.map((partner) => {
          return (
            <Tbody key={partner._id}>
              <Tr>
                <Td> {partner.id} </Td>
                <Td> {partner.Role} </Td>
                <Td> {partner.FullName} </Td>
                <Td> {partner.Status} </Td>
                <Td> {partner.RegisterAs} </Td>
                <Td> {partner.Nationality} </Td>
                <Td> {partner.TaxId} </Td>
                <Td> {partner.MobileNumber} </Td>
                <Td>
                  {" "}
                  <button
                    type="button"
                    onClick={() =>
                      login({
                        Email: partner.Email,
                        Password: partner.Password,
                      })
                    }
                  >
                    {partner.Email}
                  </button>{" "}
                </Td>
                <Td> {partner.Commission} </Td>
                <Td> {partner.BankIBAN} </Td>
                <Td>
                  <Link to={`/admin/dashboard/partners/${partner._id}`}>
                    View
                  </Link>
                </Td>
                {/* <Td> <button type='button' onClick={handleLogin}>Login</button> </Td> */}
              </Tr>
            </Tbody>
          );
        })}
      </Table>
    </div>
  );
};;

export default Partners;
