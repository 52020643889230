import React from "react";
import "../scss/Loading.scss";

const Loading = () => {
  return (
    <div className="load-page">
      <div className="loader">
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
