import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { loginC, getClients } from "../../helpers/ClientApi";
import { logOut } from "../../helpers/PartnerApi";

import { ClientContext } from "../../context/ClientContext";
import { PartnerContext } from "../../context/PartnerContext";

import "../../scss/Clients.scss";

const Clients = () => {
  const [search, setSearch] = useState();
  const { clients, setClients, storeClient, setLoading, callback } =
    useContext(ClientContext);
  const { setPartner, setIsLogged, setIsAdmin } = useContext(PartnerContext);

  useEffect(() => {
    const getAllClients = async () => {
      try {
        const result = await getClients();
        if (result.error) {
          setClients();
          setLoading(false);
          return;
        }
        setClients(result.clients);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getAllClients();
  }, [setClients, callback, setLoading]);

  // console.log(clients);
  const partnerLogout = () => {
    setPartner();
    logOut();
    setIsAdmin(false);
    setIsLogged(false);
  };

  const login = async (data) => {
    let result = await loginC(data);
    storeClient(result);
    partnerLogout();
    window.open("/client/dashboard", "_blank");
  };

  return (
    <div className="admin-client-areas">
      <h1>Kurier 247 Client List</h1>
      <div className="search-filter">
        <div className="text-input">
          <input
            type="text"
            value={search}
            placeholder="Enter your search!"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
        <div className="submit-button">
          <button type="submit">Search</button>
        </div>
      </div>

      <Table>
        <Thead>
          <Tr>
            <Th>Client ID</Th>
            <Th>FirstName</Th>
            <Th>LastName</Th>
            <Th>CompanyName</Th>
            <Th>Mobile Number</Th>
            <Th>Email</Th>
            <Th>Details</Th>
          </Tr>
        </Thead>
        {clients?.map((client) => {
          return (
            <Tbody key={client._id}>
              <Tr>
                <Td> {client.id} </Td>
                <Td> {client.FirstName} </Td>
                <Td> {client.LastName} </Td>
                <Td> {client.CompanyName} </Td>
                <Td> {client.Phone} </Td>
                <Td>
                  <button
                    type="button"
                    onClick={() =>
                      login({
                        Email: client.Email,
                        Password: client.Password,
                      })
                    }
                  >
                    {client.Email}
                  </button>
                </Td>
                <Td>
                  <Link to={`/admin/client/details/info/${client._id}`}>
                    View
                  </Link>
                </Td>
              </Tr>
            </Tbody>
          );
        })}
      </Table>
    </div>
  );
};

export default Clients;
