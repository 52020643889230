import React from 'react'
import '../scss/AboutUs.scss'

const AboutUs = () => {
  return (
    <div className="mainCointainer">
      <div className="image">
        <img src="../assets/aboutBG.jpg" alt="" />
      </div>
      <div className="text">
        KURIER 247 is a growing start-up company offering professional services
        in Berlin, Germany. We offer a wide range of services in courier and
        transportation to help make your life easier. Whether it’s Sending a
        letter urgently in an hour, transporting goods from your warehouse to
        the store, sending goods to your clients, transport your furniture from
        store to home or move to the new home we have experienced people waiting
        to discuss your requirements.
      </div>
    </div>
  );
}

export default AboutUs
