import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PartnerContext } from "../../context/PartnerContext";
import { getPartnerOrders } from "../../helpers/PartnerApi";
import { updateBooking } from "../../helpers/OrderApi";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import OrderValidation from "../../validation/OrderValidation.jsx";
import { ProductContext } from "../../context/ProductContext";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../scss/PartnerDashboard.scss";

const PartnerDashboard = () => {
  const localizer = momentLocalizer(moment);
  const navigate = useNavigate();
  const { partner } = useContext(PartnerContext);
  const {
    bookings,
    setOrder,
    partnerOrders,
    setPartnerOrders,
    setCallback,
    callback,
  } = useContext(ProductContext);
  const [checkedData, setCheckedData] = useState({});

  // console.log(bookings);
  // const [pendings, setPendings] = useState([])
  // const [completes, setComplets] = useState([])

  let pendings = [];
  let completes = [];  
  bookings.forEach((item) => {
    if (item.BookingStatus === "Pending" || item.BookingStatus === "Reject")
      pendings.push(item);
    if (item.BookingStatus === "Accomplished") completes.push(item);
  });

  const goTos = (data) => {
    navigate(`/job-details/${data.id}`);
  };

  useEffect(() => {
    const getData = async () => {
      let items = await getPartnerOrders();
      setPartnerOrders(items);
    };
    getData();
  }, [setPartnerOrders, partner.id, callback]);
  

  const [finalOrders, setFinalOrders] = useState([]);
  useEffect(() => {
    let pastOrders = [];
    partnerOrders?.forEach((item) => {
      const bookingDate = new Date(item.BookingDate);
      const oDay = bookingDate.getDate();
      // console.log(oDay);
      let currentDate = new Date();
      const cDay = currentDate.getDate();
      // console.log(cDay);
      const cHours = currentDate.getHours();
      let bookingHours = item.BookingTime?.slice(7, 10);

      if (item.BookingStatus === "Confirmed" && oDay < cDay) {
        // console.log("If triger");
        pastOrders.push(item);
      } else if (
        item.BookingStatus === "Confirmed" &&
        oDay === cDay &&
        cHours >= parseInt(bookingHours)
      ) {
        console.log("else If triger");
        pastOrders.push(item);
      }
      return item;
    });
    setFinalOrders(pastOrders);
  }, [partnerOrders, callback, setFinalOrders]);

  // let total =

  const orderData = {
    PartnerId: partner._id,
    BookingStatus: "Confirmed",
  };

  const update = async (id) => {
    const res = await updateBooking(id, orderData);
    setOrder(res);
    setCallback(!callback);
  };

  const inputHandler = (e, index) => {
    e.preventDefault();
    const id = e.target.id;
    const val = e.target.value;
    let tempOrders = [...finalOrders];
    tempOrders[index] = {
      ...tempOrders[index],
      [id]: val,
    };
    setFinalOrders(tempOrders);
    setCheckedData({});
  };

  const orderComplete = async (data) => {
    // console.log(data);
    const basePrice = data.PackageId?.PackagePrice;
    const KmPrice = (data.PackageId?.PricePerKm * data.Total_KM).toFixed(2) * 1;
    const StopagePrice =
      (data.PackageId?.StoppagePrice * data.StopagesAddress?.length).toFixed(
        2
      ) * 1;
    const loadingPrice = (data.Load_Unload / 5) * data.PackageId?.Price5min;
    const helpingPrice =
      (data.Load_Unload_Help / 60) * data.PackageId?.PricePerHelper;
    const Netto =
      (
        basePrice +
        KmPrice +
        StopagePrice +
        loadingPrice +
        helpingPrice
      ).toFixed(2) * 1;
    const Vat = (Netto * (data.PackageId?.MwSt / 100)).toFixed(2) * 1;
    const total = (Netto + Vat).toFixed(2) * 1;
    let checkedData = OrderValidation(data);
    setCheckedData(checkedData);
    if (checkedData.success) {
      let orderData = {
        ...data,
        BookingStatus: "Accomplished",
        InvoiceNumber: completes.length + 1,
        Total: total,
      };
      try {
        await updateBooking(data._id, orderData);
      } catch (error) {
        console.log(error);
      }
    }
    setCallback(!callback);
  };

  // console.log(partnerOrders);
  // console.log(finalOrders);
  // console.log(completes);
  // console.log(bookings);

  return (
    <div className="partnerDashboard">
      <div className="header">MY DASHBOARD</div>
      {partner.Status === "Active" && (
        <div className="generalOrderTable">
          <div className="general-job-table-title">General Job Board</div>
          <Table>
            <Thead>
              <Tr>
                {/* <Th>Order ID</Th> */}
                <Th>Package</Th>
                <Th>Date</Th>
                <Th>Time</Th>
                <Th>Pickup</Th>
                <Th>Dropoff</Th>
                <Th>Stopage</Th>
                <Th>Price</Th>
                <Th>Update</Th>
              </Tr>
            </Thead>
            {pendings.map((item) => {
              const date = new Date(item.BookingDate);
              const localDate = date.toLocaleDateString();
              return (
                <Tbody key={item._id}>
                  <Tr>
                    {/* <Td>DE-{item.OrderNumber}</Td> */}
                    <Td>{item.PackageId.TitleDE}</Td>
                    <Td>{localDate}</Td>
                    <Td>{item.BookingTime}</Td>
                    <Td>{item.StartAddress.PostCode}</Td>
                    <Td>{item.DeliveryAddress.PostCode}</Td>
                    <Td>{item.StopagesAddress?.length}</Td>
                    <Td>
                      {(
                        item.PackageId.PackagePrice +
                        item.Total_KM * item.PackageId.PricePerKm +
                        item.StopagesAddress.length *
                          item.PackageId.StoppagePrice +
                        ((item.PackageId.PackagePrice +
                          item.Total_KM * item.PackageId.PricePerKm +
                          item.StopagesAddress.length *
                            item.PackageId.StoppagePrice) *
                          item.PackageId.MwSt) /
                          100
                      ).toFixed(2) * 1}{" "}
                      £
                    </Td>
                    <Td>
                      <button type="button" onClick={() => update(item._id)}>
                        Confirmed
                      </button>
                    </Td>
                  </Tr>
                </Tbody>
              );
            })}
          </Table>
        </div>
      )}
      <div
        className={
          finalOrders.length !== 0 ? "pastOrderTable" : "pastOrderTable1"
        }
      >
        <div className="pastorder-table-title">Past Order</div>
        <Table>
          <Thead>
            <Tr>
              {/* <Th>Order ID</Th> */}
              <Th>Date</Th>
              <Th>Time</Th>
              <Th>Package</Th>
              <Th>Payment Method</Th>
              <Th>Loading / Wating</Th>
              <Th>Helping</Th>
              <Th>Reciver</Th>
              <Th>UPDATE</Th>
            </Tr>
          </Thead>
          {finalOrders.map((order, index) => {
            const date = new Date(order.BookingDate);
            const localDate = date.toLocaleDateString();
            return (
              <Tbody key={index}>
                <Tr>
                  {/* <Td> DE-{order.OrderNumber} </Td> */}
                  <Td> {localDate} </Td>
                  <Td> {order.BookingTime?.slice(0, 13)} </Td>
                  <Td> {order.PackageId?.Title} </Td>
                  <Td> {order.PaymentType} </Td>
                  <Td>
                    <input
                      type="number"
                      name="Load_Unload"
                      id="Load_Unload"
                      index={index}
                      defaultValue={order.Load_Unload}
                      onChange={(e) => inputHandler(e, index)}
                    />
                  </Td>
                  <Td>
                    <input
                      type="number"
                      name="Load_Unload_Help"
                      id="Load_Unload_Help"
                      index={index}
                      defaultValue={order.Load_Unload_Help}
                      onChange={(e) => inputHandler(e, index)}
                    />
                  </Td>
                  <Td>
                    <input
                      type="text"
                      name="ReciverName"
                      id="ReciverName"
                      index={index}
                      value={order.ReciverName}
                      onChange={(e) => inputHandler(e, index)}
                      required
                      style={
                        checkedData.borderColors !== undefined
                          ? checkedData.borderColors.ReciverName
                            ? {
                                border: checkedData.borderColors.ReciverName,
                              }
                            : {}
                          : {}
                      }
                    />
                  </Td>
                  <Td>
                    <button type="button" onClick={() => orderComplete(order)}>
                      Complete
                    </button>
                  </Td>
                </Tr>
              </Tbody>
            );
          })}
        </Table>
      </div>
      <div className="general-job-calender">
        <h3 className="calender-title">Calendar with all Scheduled Booking</h3>
        <Calendar
          localizer={localizer}
          events={partnerOrders?.map((order) => {
            if (order.BookingStatus !== "Reject") {
              let year = order.BookingDate.slice(0, 4);
              let month = order.BookingDate.slice(5, 7);
              let day = order.BookingDate.slice(8, 10);
              let startHour = order.BookingTime.slice(0, 2);
              let startMin = order.BookingTime.slice(3, 5);
              let endHour = order.BookingTime.slice(8, 10);
              let endMin = order.BookingTime.slice(11, 13);
              // console.log(endMin);
              return {
                title: order.PackageId.Title,
                start: new Date(year, month - 1, day, startHour, startMin),
                end: new Date(year, month - 1, day, endHour, endMin),
                id: order._id,
              };
            }
            return order;
          })}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 400 }}
          views={["day", "week", "month"]}
          step={30}
          defaultView="month"
          selectable
          onSelectEvent={(event) => goTos(event)}
        />
      </div>
    </div>
  );
};

export default PartnerDashboard;
