import React, { createContext, useState, useEffect } from "react";
import { authenticatePartner } from "../helpers/PartnerApi";
import image from "../images/Profile.png";

export const PartnerContext = createContext();

const PartnerContextProvider = ({ children }) => {
  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [authIsDone, setAuthIsDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [avatar, setAvatar] = useState(
    "https://res.cloudinary.com/kurier247/image/upload/v1636201201/KURIER247/f0wdo81mhdjogm3ttdxv.png"
  );
  const [avatarPreview, setAvatarPreview] = useState(image);
  const [pCallback, setPCallback] = useState(false);

  // last thing to get executed (after all components have been rendered already)
  useEffect(() => {
    const authMe = async () => {
      console.log("Context is trying to authenticate Partner");
      try {
        const result = await authenticatePartner();
        if (result.error || result === "") {
          setPartner();
          setIsLogged(false);
          setAuthIsDone(true);
          setError({});
          return;
        }
        setError({});
        setPartner(result);
        if (result.Role === 1) setIsAdmin(true);
        setAuthIsDone(true);
        setIsLogged(true);
      } catch (error) {
        console.log(error);
      }
    };
    authMe();
  }, [setPartner, setError]);

  // console.log(image);

  return (
    <PartnerContext.Provider
      value={{
        error,
        setError,
        partners,
        setPartners,
        partner,
        setPartner,
        loading,
        setLoading,
        avatar,
        setAvatar,
        avatarPreview,
        setAvatarPreview,
        isLogged,
        setIsLogged,
        isAdmin,
        setIsAdmin,
        authIsDone,
        setAuthIsDone,
        pCallback,
        setPCallback,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerContextProvider;
