import React, { useContext, useState } from "react";
import BookServiceForm from "./BookServiceForm.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddressFormValidation from "../validation/AddressFormValidation";
import { createOrder } from "../helpers/OrderApi";
import { useParams, useNavigate } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import { ProductContext } from "../context/ProductContext.jsx";
import "../scss/BookService.scss";

const BookService = (props) => {
  const [libraries] = useState(["places"]);

  const navigate = useNavigate();
  const {
    pickupData,
    setPickupData,
    deliveryData,
    setDeliveryData,
    stopageData,
    setStopageData,
    bookingDate,
    setBookingDate,
    bookingTime,
    setBookingTime,
    stoppageBtn,
    setStoppageBtn,
    buttonDisplay,
    setButtonDisplay,
    setValues,
    setIds,
    setNames,
    setOrder,
    stopage,
    setStopage,
    km,
    setKm,
    setCheckedData,
    setCallback,
    callback,
  } = useContext(ProductContext);

  // console.log(km);

  const timeSlots = [
    {
      value: "07:00 - 07:30",
      startTime: "7:00",
    },
    {
      value: "07:30 - 08:00",
      startTime: "7:30",
    },
    {
      value: "08:00 - 08:30",
      startTime: "8:00",
    },
    {
      value: "08:30 - 09:00",
      startTime: "8:30",
    },
    {
      value: "09:00 - 09:30",
      startTime: "9:00",
    },
    {
      value: "09:30 - 10:00",
      startTime: "9:30",
    },
    {
      value: "10:00 - 10:30",
      startTime: "10:00",
    },
    {
      value: "10:30 - 11:00",
      startTime: "10:30",
    },
    {
      value: "11:00 - 11:30",
      startTime: "11:00",
    },
    {
      value: "11:30 - 12:00",
      startTime: "11:30",
    },
    {
      value: "12:00 - 12:30",
      startTime: "12:00",
    },
    {
      value: "12:30 - 13:00",
      startTime: "12:30",
    },
    {
      value: "13:00 - 13:30",
      startTime: "13:00",
    },
    {
      value: "13:30 - 14:00",
      startTime: "13:30",
    },
    {
      value: "14:00 - 14:30",
      startTime: "14:00",
    },
    {
      value: "14:30 - 15:00",
      startTime: "14:30",
    },
    {
      value: "15:00 - 15:30",
      startTime: "15:00",
    },
    {
      value: "15:30 - 16:00",
      startTime: "15:30",
    },
    {
      value: "16:00 - 16:30",
      startTime: "16:00",
    },
    {
      value: "16:30 - 17:00",
      startTime: "16:30",
    },
    {
      value: "17:00 - 17:30",
      startTime: "17:00",
    },
    {
      value: "17:30 - 18:00",
      startTime: "17:30",
    },
    {
      value: "18:00 - 18:30",
      startTime: "18:00",
    },
    {
      value: "18:30 - 19:00",
      startTime: "18:30",
    },
    {
      value: "19:00 - 19:30",
      startTime: "19:00",
    },
    {
      value: "19:30 - 20:00",
      startTime: "19:30",
    },
    {
      value: "20:00 - 20:30",
      startTime: "20:00",
    },
    {
      value: "20:30 - 21:00",
      startTime: "20:30",
    },
  ];
  const currentDate = new Date();
  const selectedDate = new Date(bookingDate);
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  let sliceIndex = 0;
  if (hours < 7 || hours > 21) {
    sliceIndex = timeSlots.length;
  } else {
    if (minutes < 30) {
      sliceIndex = timeSlots.findIndex(
        (item) =>
          item.startTime.includes(hours + 1) && item.startTime.includes("30")
      );
    }
    if (minutes === 30) {
      sliceIndex = timeSlots.findIndex(
        (item) =>
          item.startTime.includes(hours + 1) && item.startTime.includes(minutes)
      );
    }
    if (minutes > 30) {
      sliceIndex = timeSlots.findIndex((item) =>
        item.startTime.includes(hours + 1)
      );
    }
  }
  if (selectedDate > currentDate) {
    sliceIndex = 0;
  }
  // console.log("sliceIndex", sliceIndex);
  const params = useParams();

  const pickupInputChangeHandler = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
      const name = e.target.name;
      const val = e.target.value;
      setPickupData({
        ...pickupData,
        [name]: val,
      });
    } else {
      const googleAddress = {};
      e.map((elem) => {
        // console.log(elem);
        if (elem.types[0] === "street_number") {
          googleAddress.HouseNo = elem.long_name;
        }
        if (elem.types[0] === "route") {
          googleAddress.Street = elem.long_name;
        }
        if (elem.types[0] === "postal_code") {
          googleAddress.PostCode = elem.long_name;
        }
        if (elem.types[0] === "locality") {
          googleAddress.City = elem.long_name;
        }
        return true;
      });
      setPickupData({
        ...pickupData,
        ...googleAddress,
      });
    }
    setCheckedData({});
  };

  const deliveryInputChangeHandler = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
      const name = e.target.name;
      const val = e.target.value;
      setDeliveryData({
        ...deliveryData,
        [name]: val,
      });
    } else {
      const googleAddress = {};
      e.map((elem) => {
        if (elem.types[0] === "street_number") {
          googleAddress.HouseNo = elem.long_name;
        }
        if (elem.types[0] === "route") {
          googleAddress.Street = elem.long_name;
        }
        if (elem.types[0] === "postal_code") {
          googleAddress.PostCode = elem.long_name;
        }
        if (elem.types[0] === "locality") {
          googleAddress.City = elem.long_name;
        }
        return true;
      });
      setDeliveryData({
        ...deliveryData,
        ...googleAddress,
      });
    }
    setCheckedData({});
  };

  const stoppageHandler = () => {
    const data = [...stopageData];
    data.push({
      Name: "",
      Street: "",
      HouseNo: "",
      PostCode: "",
      City: "",
      Phone: "",
      Comments: "",
    });
    setStopageData(data);
    setStoppageBtn(true);
    setButtonDisplay(true);
  };

  const entryInputChangeHandler = (e, index = 0) => {
    if (e?.preventDefault) {
      e.preventDefault();
      setValues(e.target.value);
      setIds(e.target.id);
      setNames(e.target.name);
      const name = e.target.name;
      const val = e.target.value;
      const data_2 = [...stopageData];
      if (name === "City") {
        data_2[index].City = val;
      } else if (name === "HouseNo") {
        data_2[index].HouseNo = val;
      } else if (name === "Name") {
        data_2[index].Name = val;
      } else if (name === "Phone") {
        data_2[index].Phone = val;
      } else if (name === "Street") {
        data_2[index].Street = val;
      } else if (name === "PostCode") {
        data_2[index].PostCode = val;
      } else if (name === "Comments") {
        data_2[index].Comments = val;
      }
      setStopageData(data_2);
    } else {
      const googleAddress = {};
      e.map((elem) => {
        if (elem.types[0] === "street_number") {
          googleAddress.HouseNo = elem.long_name;
        }
        if (elem.types[0] === "route") {
          googleAddress.Street = elem.long_name;
        }
        if (elem.types[0] === "postal_code") {
          googleAddress.PostCode = elem.long_name;
        }
        if (elem.types[0] === "locality") {
          googleAddress.City = elem.long_name;
        }
        return true;
      });
      const data = [...stopageData];
      data[index] = {
        ...data[index],
        ...googleAddress,
      };
      setStopageData(data);
    }
    setCheckedData({});
  };

  const fieldCheck = () => {
    if (stopageData.length > 0) {
      const item = stopageData[stopageData.length - 1];
      if (
        item.Street !== "" &&
        item.HouseNo !== "" &&
        item.PostCode !== "" &&
        item.City !== ""
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const formSubbmit = async () => {
    let pickupDataCheck = AddressFormValidation(pickupData);
    let deliveryDateCheck = AddressFormValidation(deliveryData);
    let stopageDataCheck = true;
    stopageData.forEach((item) => {
      const stopageDataItemCheck = AddressFormValidation(item);
      stopageDataCheck = stopageDataCheck && stopageDataItemCheck.success;
    });
    let stopageDataCheckArray = stopageData.map((item) =>
      AddressFormValidation(item)
    );

    setCheckedData({
      pickup: pickupDataCheck,
      delivery: deliveryDateCheck,
      stopage: stopageDataCheckArray,
    });
    if (
      pickupDataCheck.success &&
      deliveryDateCheck.success &&
      stopageDataCheck
    ) {
      try {
        const result = await createOrder({
          StartAddress: pickupData,
          DeliveryAddress: deliveryData,
          StopagesAddress: stopageData,
          Stopage: stopage,
          BookingDate: bookingDate,
          BookingTime: bookingTime,
          PackageId: params.id,
          Total_KM: km,
        });
        setOrder(result);
        setCallback(!callback);
        navigate(`/checkout/${result._id}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const arrAddresses = [pickupData, ...stopageData, deliveryData];

  const distanceCalculation = () => {
    let pickupDataCheck = AddressFormValidation(pickupData);
    let deliveryDateCheck = AddressFormValidation(deliveryData);
    let stopageDataCheck = true;
    stopageData.forEach((item) => {
      const stopageDataItemCheck = AddressFormValidation(item);
      stopageDataCheck = stopageDataCheck && stopageDataItemCheck.success;
    });
    let stopageDataCheckArray = stopageData.map((item) =>
      AddressFormValidation(item)
    );

    setCheckedData({
      pickup: pickupDataCheck,
      delivery: deliveryDateCheck,
      stopage: stopageDataCheckArray,
    });

    if (
      pickupDataCheck.success &&
      deliveryDateCheck.success &&
      stopageDataCheck
    ) {
      let totalDistance = 0;
      const saveDistance = (results, status) => {
        if (status === "OK") {
          let m = results.rows[0].elements[0].distance.value;
          totalDistance = totalDistance + m;
          // console.log(totalDistance);
          setKm(Math.ceil(totalDistance / 1609));
        } else {
          alert("Geocode was not successful due to: " + status);
        }
      };
      for (let i = 0; i < arrAddresses.length; i++) {
        if (arrAddresses[i + 1]) {
          let origin = arrAddresses[i];
          let destination = arrAddresses[i + 1];

          let service = new window.google.maps.DistanceMatrixService();
          service.getDistanceMatrix(
            {
              origins: [
                `${origin?.Street} ${origin?.HouseNo}, ${origin?.PostCode}`,
              ],
              destinations: [
                `${destination?.Street} ${destination?.HouseNo}, ${destination?.PostCode}`,
              ],
              travelMode: "DRIVING",
              // transitOptions: TransitOptions,
              // drivingOptions: DrivingOptions,
              // unitSystem: UnitSystem,
              // avoidHighways: Boolean,
              avoidTolls: true,
            },
            saveDistance
          );
        }
      }
      setStopage(stopageData?.length);
      setButtonDisplay(false);
    }
  };

  // console.log(libraries);

  return (
    <>
      <div className="bookService">
        <div className="bookServiceHeader">
          Start Address {"-->"} Stopage {"-->"} Final Destination
        </div>
        <div className="bookServiceContent">
          <LoadScript
            libraries={libraries}
            googleMapsApiKey="AIzaSyA4xQ9_V_pAU-UK8Fj0oq5lNZSlhINTAS0"
          >
            <BookServiceForm
              desc="Start Address"
              type="pickup"
              change={pickupInputChangeHandler}
            />
            <BookServiceForm
              desc="Delivery Address"
              type="delivery"
              change={deliveryInputChangeHandler}
            />
            {stopageData.length > 0
              ? stopageData.map((item, index) => (
                  <BookServiceForm
                    key={index}
                    desc={`Stopage ${index + 1}`}
                    type={"stopage"}
                    index={index}
                    data={item}
                    change={entryInputChangeHandler}
                  />
                ))
              : ""}
          </LoadScript>
          {buttonDisplay ? (
            <div className="pagebtn">
              <button
                type="submit"
                className="button-common"
                onClick={() => distanceCalculation()}
              >
                Calculate Distance
              </button>
            </div>
          ) : (
            <>
              <div className="setbookingDateTime">
                <h2 className="heading">Pick Date & Time</h2>
                <div className="date-time-picker">
                  <DatePicker
                    selected={bookingDate}
                    onChange={(date) => setBookingDate(date)}
                    minDate={new Date()}
                    showDisabledMonthNavigation
                    dateFormat="dd/MM/yyyy"
                  />
                  <select
                    name="bookingTime"
                    id="bookingTime"
                    onChange={(e) => setBookingTime(e.target.value)}
                  >
                    <option value="">Select Start Time</option>
                    {timeSlots.slice(sliceIndex).map((timeSlot, index) => (
                      <option value={timeSlot.value} key={index}>
                        {timeSlot.value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="pagebtn">
                  <button
                    type="submit"
                    color="success"
                    className="button-common"
                    onClick={formSubbmit}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </>
          )}
          {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
          {pickupData.Name &&
          pickupData.Street &&
          pickupData.HouseNo &&
          pickupData.PostCode &&
          pickupData.City &&
          deliveryData.Name &&
          deliveryData.Street &&
          deliveryData.HouseNo &&
          deliveryData.PostCode &&
          deliveryData.City ? (
            (pickupData.Name !== "" &&
              pickupData.Street !== "" &&
              pickupData.HouseNo !== "" &&
              pickupData.PostCode !== "" &&
              pickupData.City !== "" &&
              deliveryData.Name !== "" &&
              deliveryData.Street !== "" &&
              deliveryData.HouseNo !== "" &&
              deliveryData.PostCode !== "" &&
              deliveryData.City !== "" &&
              stoppageBtn === false) ||
            (stopageData.length > 0 && fieldCheck()) ? (
              <div className="pagebtn">
                <button
                  type="submit"
                  color="success"
                  className="button-common"
                  onClick={() => stoppageHandler()}
                >
                  Add Stoppage
                </button>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default BookService;
