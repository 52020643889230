import React from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { Link, useNavigate } from "react-router-dom";
import "../scss/Footer.scss";

const Footer = () => {
  const navigate = useNavigate();
  const goTo = () => {
    navigate("/contactus");
  };
  return (
    <div className="footer">
      <div className="footerMain">
        <div className="qlinks">
          <h1>Quick Links</h1>
          <Link to="/">Courier Service</Link>
          <Link to="/">Removal Service</Link>
          <Link to="/">Waste recycling</Link>
        </div>
        <div className="benifit">
          <h1>Benefits</h1>
          <Link to="/">Partner Benefits</Link>
          <Link to="/">Terms and Conditions</Link>
          <Link to="/">Data Policy</Link>
          <Link to="/">Impressum</Link>
        </div>
        <div className="news">
          <h1>Newsletter</h1>
          <p>
            Subscribe our newsletter and you can get free notified delivered
            straight to your inbox. Subscribing below.
          </p>
          <div>
            <input
              className="email text-center"
              type="email"
              name="email"
              id="eamil"
              placeholder="Enter your email..."
            />
            <button className="btn">Submit</button>
          </div>
        </div>
        <div className="help">
          <h1>Need Help ?</h1>
          <a href="tel:+0000000">
            <PhoneIcon />
            <p>00000000000</p>
          </a>
          <a href="tel:+447769372911">
            <WhatsAppIcon /> <p>000000000000</p>
          </a>
          <a href="mailto:info@kurier247.de">
            <EmailIcon /> <p>info@kurier247.de</p>
          </a>
          <button className="btn" onClick={goTo}>
            Contact Us
          </button>
        </div>
      </div>
      <div className="bottom">
        <p>
          Copyrights {new Date().getFullYear()} &copy; Courier 247 | All rights
          reserved
        </p>
        <div className="social">
          <a
            href="https://www.linkedin.com/in/ferujahammed"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="facebook">
              <div className="circle"></div>
              <div className="circle circle2"></div>
              <img src="../assets/facebook.png" alt="" />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/in/ferujahammed"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="instagram">
              <div className="circle circle3"></div>
              <img src="../assets/instagram.png" alt="Instagram" />
            </div>
          </a>

          <a
            href="https://www.linkedin.com/in/ferujahammed"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="twitter">
              <img src="../assets/twiter.png" alt="Twiter" />
            </div>
          </a>
        </div>
        <p>
          Developed by |
          <a
            href="https://www.linkedin.com/in/ferujahammed"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            {" "}
            Feruj Ahammed{" "}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
