import React, { useContext, useEffect } from "react";
import { PartnerContext } from "../../context/PartnerContext";
import { ProductContext } from "../../context/ProductContext";
import { getPartnerOrders } from "../../helpers/PartnerApi";
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../scss/PastOrder.scss";

const PastOrder = () => {
  const navigate = useNavigate();
  const { partnerOrders, setPartnerOrders, callback } =
    useContext(ProductContext);
  const { partner } = useContext(PartnerContext);

  useEffect(() => {
    const getData = async () => {
      let items = await getPartnerOrders();
      setPartnerOrders(items);
    };
    getData();
  }, [setPartnerOrders, partner.id, callback]);

  let accepts = [];
  let completes = [];
  partnerOrders.forEach((item) => {
    if (item.BookingStatus === "Confirmed") {
      accepts.push(item);
    }
    if (item.BookingStatus === "Accomplished") {
      completes.push(item);
    }
  });

  const goTo = (data) => {
    navigate(`/print/${data}`);
  };
  const details = (data) => {
    navigate(`/job-details/${data._id}`);
  };

  // console.log(completes);

  return (
    <div className="pastorderContainer">
      <div className="accept">
        <div className="header">Confirmed Orders</div>
        <Table>
          <Thead>
            <Tr>
              <Th>Order ID</Th>
              <Th>Package</Th>
              <Th>Date</Th>
              <Th>Time</Th>
              <Th>Pickup</Th>
              <Th>Dropoff</Th>
              <Th>Stopage</Th>
              <Th>Price</Th>
              <Th>Details</Th>
            </Tr>
          </Thead>
          {accepts.map((item) => {
            const date = new Date(item.BookingDate);
            const localDate = date.toLocaleDateString();
            return (
              <Tbody key={item._id}>
                <Tr>
                  <Td>{item.BookingNumber}</Td>
                  <Td>{item.PackageId.TitleDE}</Td>
                  <Td>{localDate}</Td>
                  <Td>{item.BookingTime}</Td>
                  <Td>{item.StartAddress.PostCode}</Td>
                  <Td>{item.DeliveryAddress.PostCode}</Td>
                  <Td>{item.StopagesAddress?.length}</Td>
                  <Td>
                    {(
                      item.PackageId.PackagePrice +
                      item.Total_KM * item.PackageId.PricePerKm +
                      item.StopagesAddress.length *
                        item.PackageId.StoppagePrice +
                      ((item.PackageId.PackagePrice +
                        item.Total_KM * item.PackageId.PricePerKm +
                        item.StopagesAddress.length *
                          item.PackageId.StoppagePrice) *
                        item.PackageId.MwSt) /
                        100
                    ).toFixed(2) * 1}{" "}
                    £
                  </Td>
                  <Td>
                    <button onClick={() => details(item._id)} type="button">
                      VIEW
                    </button>
                  </Td>
                </Tr>
              </Tbody>
            );
          })}
        </Table>
      </div>
      <div className="complete">
        <div className="header">Complete Orders</div>
        <Table>
          <Thead>
            <Tr>
              <Th>Invoice Number</Th>
              <Th>Package</Th>
              <Th>Date</Th>
              <Th>Time</Th>
              <Th>Price</Th>
              <Th>Print</Th>
            </Tr>
          </Thead>
          {completes.map((item) => {
            const date = new Date(item.BookingDate);
            const localDate = date.toLocaleDateString();
            return (
              <Tbody key={item._id}>
                <Tr>
                  <Td>0{item.InvoiceNumber}</Td>
                  <Td>{item.PackageId.TitleDE}</Td>
                  <Td>{localDate}</Td>
                  <Td>{item.BookingTime}</Td>
                  <Td>{item.Total} £</Td>
                  <Td>
                    <button onClick={() => goTo(item._id)} type="button">
                      VIEW
                    </button>
                  </Td>
                </Tr>
              </Tbody>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default PastOrder;
