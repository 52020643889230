import React from "react";
import "../scss/HomeAnimation.scss"

const HomeAnimation = () => {
  return (
    <div className="animationContainer">
      <div className="sun">
        <span>Courier 24/7</span>
      </div>
      <div className="mountain"></div>
      <div className="mountain1"></div>
      <div className="mountain2"></div>
      <div className="road"></div>
      <div className="cars">
        <img src="../assets/car.png" className="car" alt="" />
        <img src="../assets/car1.png" className="car1" alt="" />
      </div>
    </div>
  );
};

export default HomeAnimation;
