import React, { createContext, useState, useEffect } from "react";
import { getBookings } from "../helpers/OrderApi.js";
import { getPackages, getServices } from "../helpers/PackageApi.js";

export const ProductContext = createContext();

const ProductContextProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [bookings, setBookings] = useState([]);
  // const [invoices, setInvoices] = useState([]);

  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const [partnerOrders, setPartnerOrders] = useState([]);
  const [clientOrders, setClientOrders] = useState([]);
  const [invoice, setInvoice] = useState({});

  const [services, setServices] = useState([]);
  const [service, setService] = useState({});

  const [servicePackages, setServicePackages] = useState([]);
  const [servicePackage, setServicePackage] = useState({});

  const [checkedData, setCheckedData] = useState({});
  const [pickupData, setPickupData] = useState({});
  const [deliveryData, setDeliveryData] = useState({});
  const [stopageData, setStopageData] = useState([]);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [bookingTime, setBookingTime] = useState({ BookingTime: "" });
  const [stoppageBtn, setStoppageBtn] = useState(false);
  const [buttonDisplay, setButtonDisplay] = useState(true);
  const [checkButtonDisplay, setcheckButtonDisplay] = useState(true);
  const [values, setValues] = useState("");
  const [ids, setIds] = useState("");
  const [names, setNames] = useState("");
  const [stopage, setStopage] = useState(0);
  const [km, setKm] = useState(1);
  const [callback, setCallback] = useState(false);

  useEffect(() => {
    const getAllOrders = async () => {
      try {
        const result = await getBookings();
        if (result.error) {
          setBookings();
          setLoading(false);
          setError({});
          return;
        }
        setBookings(result);
        setLoading(false);
        setError({});
      } catch (error) {
        console.log(error);
      }
    };
    getAllOrders();
  }, [setBookings, callback, setError, setLoading]);

  // console.log(bookings);

  // useEffect(() => {
  //   const getinvoice = async () => {
  //     try {
  //       const result = await getInvoices();
  //       if (result.error) {
  //         setInvoices();
  //         setLoading(false);
  //         setError({});
  //         return;
  //       }
  //       setInvoices(result);
  //       setLoading(false);
  //       setError({});
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getinvoice();
  // }, [setInvoices, callback]);

  useEffect(() => {
    const getAllPackages = async () => {
      try {
        const result = await getPackages();
        if (result.error) {
          setServicePackages();
          setLoading(false);
          return;
        }
        setServicePackages(result);
        setLoading(false);
        setError({});
      } catch (error) {
        console.log(error);
      }
    };
    getAllPackages();
  }, [setServicePackages, callback]);

  useEffect(() => {
    const getAllServices = async () => {
      try {
        const result = await getServices();
        if (result.error) {
          setServices();
          setLoading(false);
          return;
        }
        setServices(result);
        setLoading(false);
        setError({});
      } catch (error) {
        console.log("helloooooooo", error);
      }
    };
    getAllServices();
  }, [setServices, setLoading, callback]);

  // console.log(services);

  return (
    <ProductContext.Provider
      value={{
        error,
        setError,
        orders,
        setOrders,
        order,
        setOrder,
        loading,
        setLoading,
        partnerOrders,
        setPartnerOrders,
        invoice,
        setInvoice,
        servicePackages,
        setServicePackages,
        servicePackage,
        setServicePackage,
        pickupData,
        setPickupData,
        deliveryData,
        setDeliveryData,
        stopageData,
        setStopageData,
        bookingDate,
        setBookingDate,
        bookingTime,
        setBookingTime,
        stoppageBtn,
        setStoppageBtn,
        buttonDisplay,
        setButtonDisplay,
        setValues,
        setIds,
        setNames,
        stopage,
        setStopage,
        km,
        setKm,
        setCheckedData,
        checkButtonDisplay,
        setcheckButtonDisplay,
        checkedData,
        values,
        ids,
        names,
        services,
        setServices,
        service,
        setService,
        callback,
        setCallback,
        bookings,
        setBookings,
        // invoices,
        // setInvoices,
        clientOrders,
        setClientOrders,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContextProvider;
