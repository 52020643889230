import axios from "axios";
import { loadTokenFromStorage } from "./LocalStorage";

const token = loadTokenFromStorage();

axios.defaults.baseURL =
  process.env.REACT_APP_API_BASE_URL || `http://localhost:5000`; // set our API server url
token && (axios.defaults.headers.token = token); // load authentication token
// this will be now used on all our authentication requests

const extractApiError = (errAxios) => {
  return errAxios.response
    ? errAxios.response.data
    : { error: { message: "API not reachable" } };
};

// after login we need to place the new, fresh token in the Axios header
// => the token will then be sent on every request to the backend!
export const updateAuthHeader = (token) => {
  if (token) {
    axios.defaults.headers.token = token;
  } else {
    delete axios.defaults.headers.token;
  }
};



export const authenticateClient = async () => {
  try {
    const response = await axios.post(`/clients/auth`);
    // console.log(response.data);
    return response.data;
  } catch (err) {
    let error = extractApiError(err);
    return error;
  }
};

export const login = async (data) => {
  try {
    const response = await axios.post(`/clients/login`, data);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const googleLogin = async (data) => {
  // console.log("api..................", data);
  try {
    const response = await axios.post(`/clients/googleLogin`, data);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const register = async (data) => {
  try {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const response = await axios.post(`/clients/register`, data, config);
    // console.log("api..............45....", response);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const getClients = async () => {
  try {
    const response = await axios.get(`/clients/admin/clients`);
    return response.data;
  } catch (error) {
    // console.log(error);
    return extractApiError(error);
  }
};

export const loginC = async (data) => {
  try {
    const response = await axios.post(`/clients/adminlogin`, data);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const addBillAddress = async (id, data) => {
  // console.log(data);
  try {
    const response = await axios.post(`/clients/${id}/billaddress`, data);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const updateBillAddress = async (id, data) => {
  try {
    const response = await axios.patch(`/api/clients/${id}/billaddress`, data);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const getClientOrders = async () => {
  try {
    const response = await axios.get(`/clients/orders`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getClient = async (id) => {
  try {
    const response = await axios.get(`/clients/admin/client/${id}`)
    return response.data
  } catch (error) {
    return extractApiError(error);
  }
}