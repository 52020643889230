import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPartnerOrders } from "../helpers/PartnerApi";
import "../scss/OrderDetails.scss";

const OrderDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [reqOrder, setReqOrder] = useState({});
  const date = new Date(reqOrder.BookingDate);
  const localDate = date.toLocaleDateString();

  useEffect(() => {
    const getdata = async () => {
      const res = await getPartnerOrders();
      res.filter((order) => {
        if (order._id === params.id) {
          setReqOrder(order);
        }
        return order;
      });
    };
    getdata();
  }, [params.id]);

  const start =
    reqOrder.StartAddress?.Street +
    " " +
    reqOrder.StartAddress?.HouseNo +
    ", " +
    reqOrder.StartAddress?.PostCode +
    " " +
    reqOrder.StartAddress?.City;
  const end =
    reqOrder.DeliveryAddress?.Street +
    " " +
    reqOrder.DeliveryAddress?.HouseNo +
    ", " +
    reqOrder.DeliveryAddress?.PostCode +
    " " +
    reqOrder.DeliveryAddress?.City;

  // console.log(reqOrder);

  return (
    <div className="container">
      <div className="heading">Order Details</div>
      <div className="jobdetails-body">
        <div className="info">
          <div className="field">Date: {localDate} </div>
          <div className="field">Time: {reqOrder.BookingTime} </div>
          <div className="field">Package: {reqOrder.PackageId?.Title} </div>
          <div className="field">Payment Type: {reqOrder.PaymentType} </div>
          <div className="field">
            Customer Name: {reqOrder.ClientId?.FullName}{" "}
          </div>
          <div className="field">
            Contact Number: {reqOrder.ClientId?.Phone}
          </div>
        </div>
        <div className="job-addresses">
          <div className="address-field">
            <h2>Start Address</h2>
            <p>Name: {reqOrder.StartAddress?.Name}</p>
            <p>
              Phone:{" "}
              <a href="tel:+{reqOrder.StartAddress?.Phone}">
                {reqOrder.StartAddress?.Phone}
              </a>
            </p>
            <p>Note: {reqOrder.StartAddress?.Comments}</p>
            <p>
              Address:{" "}
              <a
                href={`https://google.com/maps/?hl=de&q=${start}`}
                target="_blank"
                rel="noreferrer"
              >
                {start}
              </a>
            </p>
          </div>
          {reqOrder.StopagesAddress?.map((stopage, index) => {
            let stop =
              stopage?.Street +
              " " +
              stopage?.HouseNo +
              ", " +
              stopage?.PostCode +
              " " +
              stopage?.City;
            return (
              <div className="address-field" key={stopage._id}>
                <h2>Stopage {`${index + 1}`}</h2>
                <p>Name: {stopage?.Name}</p>
                <p>
                  Phone: <a href="tel:+{stopage?.Phone}">{stopage?.Phone}</a>
                </p>
                <p>Note: {stopage?.Comments}</p>

                <p>
                  Address:{" "}
                  <a
                    href={`https://google.com/maps/?hl=de&q=${stop}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {stop}
                  </a>
                </p>
              </div>
            );
          })}
          <div className="address-field">
            <h2>Delivery Address</h2>
            <p>Name: {reqOrder.DeliveryAddress?.Name}</p>
            <p>
              Phone:{" "}
              <a href="tel:+{reqOrder.DeliveryAddress?.Phone}">
                {reqOrder.DeliveryAddress?.Phone}
              </a>
            </p>
            <p>Note: {reqOrder.DeliveryAddress?.Comments}</p>
            <p>
              Address:{" "}
              <a
                href={`https://google.com/maps/?hl=de&q=${end}`}
                target="_blank"
                rel="noreferrer"
              >
                {end}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="btn">
        <button
          onClick={() => navigate(-1)}
          type="button"
          id="return"
          className="button-common"
        >
          {" "}
          Take me back
        </button>
      </div>
    </div>
  );
};

export default OrderDetails;
