import { Box, Button } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import error from "../images/Error.png";
import '../scss/ErrorDisplay.scss'

const Error404 = () => {
    const navigate = useNavigate();
    return (
        <div className='not-found-page'>
            <Box
                width='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                pt='2rem'>
                <img
                    height='420px'
                    src={error}
                    alt='Error'
                    mt='10rem'
                    style={{backgroundColor:"red"}}
                />
                <Box pt='3rem'>
                    <Button
                        size='large'
                        color='primary'
                        variant='outlined'
                        onClick={() => navigate(-1)}>
                        Take me back
                    </Button>
                </Box>
            </Box>
        </div>
    );
};
export default Error404;
