import React, { useContext } from "react";
import { ProductContext } from "../context/ProductContext.jsx";
import visa from "../images/visa-master.png";
import "../scss/YourBill.scss";

const YourBill = ({ item }) => {
  //console.log(item);
  const { stopage, km } = useContext(ProductContext);

  const KmPrice = (item.PricePerKm * km).toFixed(2) * 1;
  const StopagePrice = (item.StoppagePrice * stopage).toFixed(2) * 1;
  let Netto = (item.PackagePrice + KmPrice + StopagePrice).toFixed(2) * 1;
  if (item.ServiceID === "60a5d302b16800349463a83a") {
    Netto = (item.PackagePrice + KmPrice + StopagePrice).toFixed(2) * 1 * 2;
  }
  let Vat = (Netto * (item.MwSt / 100)).toFixed(2) * 1;
  let Total = (Netto + Vat).toFixed(2) * 1;

  return (
    <>
      <div className="bill-main">
        <div className="bill-title">Price Breakdown</div>
        <div className="bill-content">
          <div className="bill-content-field">
            <span>Package :</span>
            <span>{item.Title}</span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span>BasePrice :</span>
            <span>{item.PackagePrice} £</span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span>Distance Price:</span>
            <span>{KmPrice} £</span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span>Stoppage Price :</span>
            <span>{StopagePrice} £</span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span>Netto sum :</span>
            <span>{Netto} £</span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span>VAT 20% :</span>
            <span>{Vat} £</span>
          </div>
          <hr />

          <div className="bill-content-field total">
            <span>Total :</span>
            <span>{Total} £</span>
          </div>
          <hr />
          <hr />
        </div>

        <div className="bill-type">
          <div className="p-3">CASH / CARD / BANK TRANSFER</div>
          <img src={visa} alt="cards" />
        </div>
      </div>
    </>
  );
};
export default YourBill;
