import React from "react";
import "../scss/ThankYou.scss";

const ThankYou = () => {
  return (
    <div className="mainContainer">
      <div className="heading">
        <img src="../assets/logo.png" alt="Courier 247" />
      </div>
      <div className="body">
        <h1>Thank You for chosing our service!</h1>
        <p>
          We have recived your booking request. We will soon check all input,
          and update as soon as possible.
        </p>
        <p>
          Please keep an eye 👀 on your Email and don't forget to check your
          spam folder!
        </p>
        <p>You can also be able to check the booking status on your profile.</p>
      </div>
    </div>
  );
};

export default ThankYou;
