import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_API_BASE_URL || `http://localhost:5000`;
axios.defaults.withCredentials = true;

const extractApiError = (errAxios) => {
  return errAxios.response
    ? errAxios.response.data
    : { error: { message: "API not reachable" } };
};

export const getPackages = async () => {
  try {
    const response = await axios.get(`/packages`);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const getPackage = async (id) => {
  try {
    const response = await axios.get(`/packages/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createPackage = async (data) => {
  try {
    const response = await axios.post(`/packages`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePackage = async (id, data) => {
  try {
    const response = await axios.patch(`/packages/${id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Services

export const getServices = async () => {
  try {
    const response = await axios.get(`/services`);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const getService = async (id) => {
  try {
    const response = await axios.get(`/services/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createService = async (data) => {
  try {
    const response = await axios.post(`/services`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateService = async (id, data) => {
  try {
    const response = await axios.patch(`/services/${id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

