import React, { createContext, useState, useEffect } from "react";
import {
  authenticateClient,
  updateAuthHeader
} from "../helpers/ClientApi";
import {
  clearTokenInStorage,
  clearUserInStorage,
  loadTokenFromStorage,
  loadUserFromStorage,
  setTokenInStorage,
  setUserInStorage,
} from "../helpers/LocalStorage";
import image from "../images/Profile.png";

export const ClientContext = createContext();

const ClientContextProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(loadUserFromStorage());
  const [token, setToken] = useState(loadTokenFromStorage());
  const [isClientLogged, setIsClientLogged] = useState(false);
  const [authClientIsDone, setAuthClientIsDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientError, setClientError] = useState({});
  const [avatar, setAvatar] = useState(
    "https://res.cloudinary.com/kurier247/image/upload/v1621480180/KURIER247/eebejp7q9hirkoz6meso.jpg"
  );
  const [avatarPreview, setAvatarPreview] = useState(image);
  const [callback, setCallback] = useState(false);

  const storeClient = ({ client, token }) => {
    setUserInStorage(client);
    setTokenInStorage(token);
    setClient(client);
    setToken(token);
    updateAuthHeader(token);
  };

  // clear user & token info on logout
  const clearClient = () => {
    clearTokenInStorage();
    clearUserInStorage();
    setClient();
    setToken();
    updateAuthHeader("");
    setAuthClientIsDone(true);
    setIsClientLogged(false);
  };

  // last thing to get executed (after all components have been rendered already)
  useEffect(() => {
    const authMe = async () => {
      console.log("Context is trying to authenticate Client");
      try {
        const result = await authenticateClient();
        // console.log(result);
        if (result.error || result === "") {
          clearClient();
          setClient();
          setAuthClientIsDone(true);
          return;
        }
        setClient(result)
        setIsClientLogged(true);
        setAuthClientIsDone(true);
      } catch (error) {
        console.log(error);
      }
    };
    authMe();
  }, [setIsClientLogged, callback]);

  return (
    <ClientContext.Provider
      value={{
        clientError,
        setClientError,
        clients,
        setClients,
        client,
        setClient,
        loading,
        setLoading,
        avatar,
        setAvatar,
        avatarPreview,
        setAvatarPreview,
        isClientLogged,
        setIsClientLogged,
        authClientIsDone,
        setAuthClientIsDone,
        token,
        setToken,
        storeClient,
        clearClient,
        callback,
        setCallback,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientContextProvider;
