import React, { useContext, useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import ComponentToPrint from "./Pdf";
import "../scss/Print.scss";
import { ProductContext } from "../context/ProductContext";
import { useParams, useNavigate } from "react-router-dom";

const Print = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [reqOrder, setReqOrder] = useState({});
  const { bookings } = useContext(ProductContext);
  const componentRef = useRef(null);

  useEffect(() => {
    bookings.map((order) => {
      if (order.BookingStatus === "Accomplished" && order._id === params.id) {
        setReqOrder(order);
      }
      return order;
    });
  }, [bookings, params.id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="print-main">
      <ComponentToPrint
        ref={componentRef}
        reqOrder={reqOrder}
      />
      <div className="btn">
        <button
          onClick={() => navigate(-1)}
          type="button"
          id="return"
          className="button-common"
        >
          {" "}
          Take me back
        </button>
        <button onClick={handlePrint} type="button">
          Print this out!
        </button>
      </div>
    </div>
  );
};

export default Print;
