import React, { useState } from "react";
import FaceIcon from "@material-ui/icons/Face";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import "../scss/ContactUs.scss";

const ContactUs = () => {
  const [contactForm, setContactForm] = useState({
    FullName: "",
    Phone: "",
    Email: "",
    Message: "",
  });
  const [submit, setSubmit] = useState(false);

  const handleChange = (e) => {
    setContactForm({ ...contactForm, [e.target.name]: e.target.value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    // console.log("Form Submit");
      setSubmit(true);
      setContactForm()
  };

  // console.log(contactForm);

  return (
    <div className="contactForm">
      <div className="header">Contact Us</div>
      <form onSubmit={formSubmit}>
        {submit ? (
          <div style={{color: "green", fontWeight:"bold"}}>
            Thanks, We have recived your Message, We will Update you ASAP!
          </div>
        ) : null}
        <div className="input">
          <FaceIcon />
          <input
            type="text"
            placeholder="Full Name *"
            required
            name="FullName"
            value={contactForm.FullName}
            onChange={handleChange}
          />
        </div>
        <div className="input">
          <PhoneIcon />
          <input
            type="text"
            placeholder="Phone No *"
            required
            name="Phone"
            value={contactForm.Phone}
            onChange={handleChange}
          />
        </div>
        <div className="input">
          <EmailIcon />
          <input
            type="email"
            placeholder="Email *"
            required
            name="Email"
            value={contactForm.Email}
            onChange={handleChange}
          />
        </div>
        <div className="input">
          <textarea
            type="text"
            placeholder="Please tell us ..."
            required
            name="Message"
            value={contactForm.Message}
            onChange={handleChange}
          />
        </div>
        <div className="input">
          <input type="submit" value="SEND" className="sendBtn" />
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
