import React from "react";
import {Link} from 'react-router-dom'
import "../scss/ServiceCard.scss";

const ServiceCard = ({service}) => {
  return (
    <Link className="serviceCard" to={`/services/${service._id}`}>
      <img src={service.SmallImage.url} alt="" />
      <h1>{service.Title}</h1>
      <p>{service.SmallDetails}</p>
    </Link>
  );
};

export default ServiceCard;
