import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import PricingCard from "../components/PricingCard";
import { ProductContext } from "../context/ProductContext";
import { getPackage } from "../helpers/PackageApi";
import BookService from "../components/BookService.jsx";
import YourBill from "../components/YourBill.jsx";
import "../scss/BookingProgress.scss";

const BookingProgress = () => {
  const params = useParams();
  const { servicePackage, setServicePackage } = useContext(ProductContext);

  useEffect(() => {
    const get = async () => {
      const result = await getPackage(params.id);
      setServicePackage(result);
    };
    get();
  }, [params.id, setServicePackage]);

  return (
    <div className="main">
      <div className="title">Service Price Calculation</div>
      <div className="pageContent">
        <div className="serviceInfo">
          <BookService />
        </div>
        <div className="package">
          <YourBill item={servicePackage} />
          <PricingCard itemData={servicePackage} />
        </div>
        <div className="bill"></div>
      </div>
    </div>
  );
};

export default BookingProgress;
