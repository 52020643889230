import React, { Fragment, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FaceIcon from "@material-ui/icons/Face";
import PhoneIcon from "@material-ui/icons/Phone";
import { GoogleLogin } from "react-google-login";
import { PartnerContext } from "../../context/PartnerContext";
import { login, googleLogin, register } from "../../helpers/PartnerApi";
import "../../scss/Auth.scss";
import Loading from "../Loading";

const PartnerAuth = () => {
  const {
    // partner,
    setPartner,
    avatar,
    setAvatar,
    avatarPreview,
    setAvatarPreview,
    setError,
    setIsLogged,
    setIsAdmin,
    loading,
    setLoading,
    pCallback,
    setPCallback,
  } = useContext(PartnerContext);

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const loginTab = useRef(null);
  const registerTab = useRef(null);
  const switcherTab = useRef(null);

  const [form, setForm] = useState({
    FirstName: "",
    FamilyName: "",
    MobileNumber: "",
    Email: "",
    Password: "",
  });

  const a = Math.ceil(Math.random() * 255);
  const b = Math.ceil(Math.random() * 255);
  const c = Math.ceil(Math.random() * 255);
  const number = Number(`${c + b}${a}`);

  const { FirstName, FamilyName, Email, Password, MobileNumber } = form;

  const success = (data) => {
    setPartner(data);
    if (data.Role === 1) setIsAdmin(true);
    setIsLogged(true);
    setLoading(false);
    setError({});
    setPCallback(!pCallback);
  }

  const responseGoogle = async (response) => {
    setError({});
    const { email, familyName, givenName, googleId, imageUrl } =
      response.profileObj;
    const data = {
      Email: email,
      googleId,
      FirstName: givenName,
      FamilyName: familyName,
      Avatar: imageUrl,
    };
    const promptInput = Number(prompt(`Please Type  ${number}  Bellow: `));
    if (number === promptInput) {
      setLoading(true)
      const result = await googleLogin(data);
      if (result.error) {
        setPartner();
        return setError(result.error);
      }
      success(result)
    } else {
      alert(`Validation failed, try again!`);
    }
  };

  const handleChange = (e) => {
    setError({});
    if (e.target.name === "Avatar") {
      const reader = new FileReader();
      console.log(reader);
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    setError({});
    const promptInput = Number(prompt(`Please Type  ${number}  Bellow: `));
    if (number === promptInput) {
      const result = await login(form);
      if (result.error) {
        setPartner();
        setLoading(false);
        setError(result.error);
        return;
      }
      success(result);
    } else {
      alert(`Validation failed, try again!`);
    }
  };

  const registerSubmit = async (e) => {
    e.preventDefault();
    let myForm = new FormData();
    myForm.set("FirstName", FirstName);
    myForm.set("FamilyName", FamilyName);
    myForm.set("MobileNumber", MobileNumber);
    myForm.set("Email", Email);
    myForm.set("Password", Password);
    myForm.set("Avatar", avatar);
    const result = await register(myForm);
    console.log(result);
    if (result.error) {
      setPartner();
      setError(result.error);
      return;
    }
    success(result);
  };

  const switchTabs = (e, tab) => {
    if (tab === "login") {
      switcherTab.current.classList.add("shiftToNeutral");
      switcherTab.current.classList.remove("shiftToRight");

      registerTab.current.classList.remove("shiftToNeutralForm");
      loginTab.current.classList.remove("shiftToLeft");
    }
    if (tab === "register") {
      switcherTab.current.classList.add("shiftToRight");
      switcherTab.current.classList.remove("shiftToNeutral");

      registerTab.current.classList.add("shiftToNeutralForm");
      loginTab.current.classList.add("shiftToLeft");
    }
  };

  // console.log(avatar);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="authContainer">
          <div className="authBox">
            <div>
              <div className="authToggle">
                <p onClick={(e) => switchTabs(e, "login")}>LOGIN</p>
                <p onClick={(e) => switchTabs(e, "register")}>REGISTER</p>
              </div>
              <button ref={switcherTab}></button>
            </div>
            <GoogleLogin
              className="google-login"
              clientId="884341565533-nu6t3be9dr6q0bbm8c3n8v754hbjcohh.apps.googleusercontent.com"
              buttonText="USE GOOGLE"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
            <form className="loginForm" ref={loginTab} onSubmit={loginSubmit}>
              <div className="loginEmail">
                <MailOutlineIcon />
                <input
                  type="email"
                  placeholder="Email"
                  required
                  name="Email"
                  value={form.Email}
                  onChange={handleChange}
                />
              </div>
              <div className="loginPassword">
                {showPassword ? (
                  <LockOpenIcon onClick={handleShowPassword} />
                ) : (
                  <LockOutlinedIcon onClick={handleShowPassword} />
                )}

                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  name="Password"
                  value={form.Password}
                  onChange={handleChange}
                />
              </div>
              <Link to="/password/forgot">Forget Password ?</Link>
              <input type="submit" value="Login" className="loginBtn" />
            </form>
            <form
              className="signUpForm"
              ref={registerTab}
              encType="multipart/form-data"
              onSubmit={registerSubmit}
            >
              <div className="signUpName">
                <FaceIcon />
                <input
                  type="text"
                  placeholder="First Name"
                  required
                  name="FirstName"
                  value={form.FirstName}
                  onChange={handleChange}
                />
              </div>
              <div className="signUpName">
                <FaceIcon />
                <input
                  type="text"
                  placeholder="Family Name"
                  required
                  name="FamilyName"
                  value={form.FamilyName}
                  onChange={handleChange}
                />
              </div>
              <div className="signUpName">
                <PhoneIcon />
                <input
                  type="text"
                  placeholder="Mobile Number"
                  required
                  name="MobileNumber"
                  value={form.MobileNumber}
                  onChange={handleChange}
                  maxLength={15}
                />
              </div>
              <div className="signUpEmail">
                <MailOutlineIcon />
                <input
                  type="email"
                  placeholder="Email"
                  required
                  name="Email"
                  value={form.Email}
                  onChange={handleChange}
                />
              </div>
              <div className="signUpPassword">
                {showPassword ? (
                  <LockOpenIcon onClick={handleShowPassword} />
                ) : (
                  <LockOutlinedIcon onClick={handleShowPassword} />
                )}
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  name="Password"
                  value={form.Password}
                  onChange={handleChange}
                />
              </div>

              <div className="registerImage">
                <img src={avatarPreview} alt="Avatar Preview" />
                <input
                  type="file"
                  name="Avatar"
                  accept="image/*"
                  onChange={handleChange}
                />
              </div>
              <input type="submit" value="Register" className="signUpBtn" />
            </form>
          </div>
        </div>
      )}
    </>
  );
};
export default PartnerAuth;
