import React, { useContext } from "react";
import BugReportIcon from "@material-ui/icons/BugReport";
import { ClientContext } from "../context/ClientContext";
import { PartnerContext } from "../context/PartnerContext";

import "../scss/ErrorDisplay.scss";

const ErrorDisplay = () => {
  const { clientError } = useContext(ClientContext);
  const { error } = useContext(PartnerContext);
  return (
    <div
      className={
        error.message || clientError.message
          ? "global-error slide-in"
          : "global-error"
      }
    >
      <span>
        <BugReportIcon />
        {error.message || clientError.message}
      </span>
    </div>
  );
};

export default ErrorDisplay;
