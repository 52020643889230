import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ClientContext } from "../../context/ClientContext";
import { getClient, addBillAddress } from "../../helpers/ClientApi";
import "../../scss/ClientInfo.scss";

const ClientInfo = () => {
  const params = useParams();
  const {
    client,
    setClient,
    clearClient,
    setClientError,
    callback,
    setCallback,
  } = useContext(ClientContext);

  const [form, setForm] = useState({});
  const [addressForm, setAddressForm] = useState({});

  useEffect(() => {
    const get = async () => {
      try {
        const result = await getClient(params.id);
        if (result.error) {
          setClient();
          return;
        }
        setClient(result);
        setForm(result)
        setAddressForm(result.InvoiceAddress);
      } catch (error) {
        console.log(error);
      }
    };
    get();
  }, [setClient, params.id]);

  const handleChange = (e) => {
    console.log(e);
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleAddressChange = (e) => {
    setAddressForm({ ...addressForm, [e.target.name]: e.target.value });
  };

  const addressSubmit = async (e) => {
    e.preventDefault();
    const result = await addBillAddress(client._id, addressForm);
    // console.log(result);
    if (result.error) {
      clearClient();
      return setClientError(result.error);
    }
    setClient(result);
    setCallback(!callback);
  };

  console.log(client);
  console.log(addressForm);

  return (
    <div className="clientInfoContainer">
      <div className="header">Client Details Info</div>
      <div className="body">
        <form action="" className="info">
          <div className="fields">
            <p>First Name: </p>
            <input
              type="text"
              placeholder="First Name"
              required
              name="FirstName"
              value={form?.FirstName}
              onChange={handleChange}
            />
          </div>
          <div className="fields">
            <p>Last Name: </p>
            <input
              type="text"
              placeholder="Last Name"
              required
              name="LastName"
              value={form?.LastName}
              onChange={handleChange}
            />
          </div>
          <div className="fields">
            <p>Full Name: </p>
            <input
              type="text"
              placeholder="Full Name"
              required
              name="FullName"
              value={form?.FullName}
              onChange={handleChange}
            />
          </div>
          <div className="fields">
            <p>Email: </p>
            <input
              type="email"
              placeholder="Email"
              required
              name="Email"
              value={form?.Email}
              onChange={handleChange}
            />
          </div>
          <div className="fields">
            <p>Contact No: </p>
            <input
              type="input"
              placeholder="Phone"
              required
              name="Phone"
              value={form?.Phone}
              onChange={handleChange}
            />
          </div>
          <div className="btn">
            <input type="submit" value="Update" />
          </div>
        </form>
        <form className="address" onSubmit={addressSubmit}>
          <div className="fields">
            <p>Company Name: </p>
            <input
              type="text"
              placeholder="Company Name"
              name="BusinessName"
              value={addressForm?.BusinessName}
              onChange={handleAddressChange}
            />
          </div>
          <div className="fields">
            <p>Name: </p>
            <input
              type="text"
              placeholder="Name *"
              required
              name="Name"
              value={addressForm?.Name}
              onChange={handleAddressChange}
            />
          </div>
          <div className="fields">
            <p>Street Name: </p>
            <input
              type="text"
              placeholder="Street *"
              required
              name="Street"
              value={addressForm?.Street}
              onChange={handleAddressChange}
            />
          </div>
          <div className="fields">
            <p>House No: </p>
            <input
              type="text"
              placeholder="House Number *"
              required
              name="HouseNumber"
              value={addressForm?.HouseNumber}
              onChange={handleAddressChange}
            />
          </div>
          <div className="fields">
            <p>Post Code: </p>
            <input
              type="text"
              placeholder="Post Code *"
              required
              name="PostCode"
              value={addressForm?.PostCode}
              onChange={handleAddressChange}
            />
          </div>
          <div className="fields">
            <p>City: </p>
            <input
              type="text"
              placeholder="City *"
              required
              name="City"
              value={addressForm?.City}
              onChange={handleAddressChange}
            />
          </div>
          <div className="btn">
            <input type="submit" value="Update" />
          </div>
        </form>
      </div>
      <div className="btn">
        <button>RETURN BACK</button>
      </div>
    </div>
  );
};

export default ClientInfo;
