import React, { useContext } from "react";
import PricingCard from "../components/PricingCard";
import { ProductContext } from "../context/ProductContext";
import "../scss/Pricing.scss";

const Pricing = () => {
  const { servicePackages } = useContext(ProductContext);
  return (
    <div className="pricingContainer">
      <h2 className="pricesHeading">OUR PACKAGES</h2>
      <div className="packages">
        {servicePackages &&
          servicePackages.map((itemData) => (
            <PricingCard key={itemData._id} itemData={itemData} />
          ))}
      </div>
    </div>
  );
};

export default Pricing;
