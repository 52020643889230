import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../scss/MobileMenu.scss"
import PhoneIcon from "@material-ui/icons/Phone";
import { ClientContext } from "../context/ClientContext";
import { PartnerContext } from "../context/PartnerContext";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { logOut } from "../helpers/PartnerApi";

const MobileMenu = ({ positionRight }) => {
  const navigate = useNavigate();
  const { isLogged, isAdmin, setPartner, setIsLogged, partner, setIsAdmin } =
  useContext(PartnerContext);
const { isClientLogged, clearClient } = useContext(ClientContext);
  const [isActive, setIsActive] = useState(false)

  const setToggle = ()=>{
    setIsActive(!isActive)
  }
  const partnerLogout = () => {
    setPartner();
    logOut();
    setIsAdmin(false);
    setIsLogged(false);
    navigate("/");
  };
  const clientLogout = () => {
    clearClient();
    navigate("/");
  };

  return (
    <>
      <div className="mobileMenu" style={{ right: `${positionRight}px` }}>
        <div className="image">
          <Link to="/" onClick={() => setIsActive(false)}>
            <img src="../assets/logo.png" alt="Moving Service" />
          </Link>
        </div>
        <a href="tel:+07769372911">
          <PhoneIcon /> 0000000000
        </a>
        <div className="line-container" onClick={setToggle}>
          <div className={isActive ? "line line-1" : "line"}></div>
          <div className={isActive ? "line line-2" : "line"}></div>
          <div className={isActive ? "line line-3" : "line"}></div>
        </div>
      </div>
      <div
        className={isActive ? "menu-container show " : "menu-container hidden"}
      >
        <Link to="/packages" onClick={setToggle}>
          PRICING
        </Link>
        {!isClientLogged && !isLogged && (
          <>
            <Link to="/client/login" onClick={setToggle}>
              CLIENT LOGIN
            </Link>
            <Link to="/partner/login" onClick={setToggle}>
              PARTNER LOGIN
            </Link>
          </>
        )}

        {isAdmin && (
          <>
            <Link to="/admin/dashboard/partners" onClick={setToggle}>
              PARTNERS
            </Link>
            <Link to="/admin/dashboard/clients" onClick={setToggle}>
              CLIENTS
            </Link>
            <Link to="/admin/orders" onClick={setToggle}>
              ORDERS
            </Link>
            <Link to="/admin/packages" onClick={setToggle}>
              PACKAGES
            </Link>
            <Link to="/admin/services" onClick={setToggle}>
              SERVICES
            </Link>
            <Link to="/admin/accounts" onClick={setToggle}>
              ACCOUNTS
            </Link>
            <Link to="/admin/dashboard" onClick={setToggle}>
              ADMIN DASHBOARD
            </Link>
            <span>
              <ExitToAppIcon onClick={partnerLogout} />
            </span>
          </>
        )}

        {!isClientLogged && !isAdmin && isLogged && (
          <>
            <Link to="/partner/dashboard" onClick={setToggle}>
              PARTNER DASHBOARD
            </Link>
            <Link to={`/partner/profile/${partner._id}`} onClick={setToggle}>
              PROFILE
            </Link>
            <Link to="/partner/orders" onClick={setToggle}>
              MY ORDERS
            </Link>
            <span onClick={setToggle}>
              <ExitToAppIcon onClick={partnerLogout} />
            </span>
          </>
        )}

        {isClientLogged && !isLogged && (
          <>
            <Link to="/client/dashboard" onClick={setToggle}>
              CLIENT DASHBOARD
            </Link>
            <Link to="/" onClick={setToggle}>
              PROFILE
            </Link>
            <Link to="/" onClick={setToggle}>
              ADDRESS
            </Link>
            <span>
              <ExitToAppIcon onClick={clientLogout} />
            </span>
          </>
        )}
      </div>
      <div className={isActive ? "overlay" : "hidden"}></div>
    </>
  );
};

export default MobileMenu;
