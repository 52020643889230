import React, { useContext, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { ProductContext } from "../context/ProductContext";
import "../scss/BookServiceForm.scss";

const BookServiceForm = (props) => {
  const { checkedData, pickupData, deliveryData, stopageData } =
    useContext(ProductContext);
  const [address, setAddress] = useState(null);

  const onPlaceChanged = () => {
    // console.log(address.getPlace());
    props.change(address.getPlace().address_components, props.index);
  };
  const onGooglePlaceLoad = (autocomplete) => {
    setAddress(autocomplete);
  };

  let finalValue = pickupData;
  if (props.type === "delivery") {
    finalValue = deliveryData;
  } else if (props.type === "stopage") {
    finalValue = stopageData[props.index];
  }

  let finalCheckedData = {};
  if (props.type === "pickup") {
    finalCheckedData = checkedData.pickup || {};
  } else if (props.type === "delivery") {
    finalCheckedData = checkedData.delivery || {};
  } else if (props.type === "stopage") {
    finalCheckedData = checkedData.stopage
      ? checkedData.stopage[props.index]
      : {};
  }

  return (
    <div className="bookServiceFormMain">
      <h2 className={props.class}>{props.desc}</h2>
      <input
        type="text"
        name={`Name`}
        id={`${props.type}_name`}
        checked="checked"
        onChange={(e) => props.change(e, props.index)}
        placeholder="Name *"
        required
        style={
          finalCheckedData?.borderColors !== undefined
            ? finalCheckedData.borderColors.Name
              ? { border: finalCheckedData.borderColors.Name }
              : {}
            : {}
        }
      />

      <Autocomplete onLoad={onGooglePlaceLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          name={`Street`}
          placeholder={`House Number & Street Name *`}
          onChange={(e) => props.change(e, props.index)}
          value={finalValue.Street}
          required
          style={
            finalCheckedData?.borderColors !== undefined
              ? finalCheckedData.borderColors.Street
                ? { border: finalCheckedData.borderColors.Street }
                : {}
              : {}
          }
        />
      </Autocomplete>
      <input
        type="text"
        name={`HouseNo`}
        id={`${props.type}_HouseNo`}
        onChange={(e) => props.change(e, props.index)}
        placeholder=""
        required
        style={
          finalCheckedData?.borderColors !== undefined
            ? finalCheckedData.borderColors.HouseNo
              ? { border: finalCheckedData.borderColors.HouseNo }
              : {}
            : {}
        }
        value={finalValue.HouseNo}
      />
      <input
        type="input"
        name={`PostCode`}
        placeholder="Post Code *"
        id={`${props.type}_PostCode`}
        onChange={(e) => props.change(e, props.index)}
        maxLength="7"
        pattern="[+-]?\d+(?:[.,]\d+)?"
        required
        style={
          finalCheckedData?.borderColors !== undefined
            ? finalCheckedData.borderColors.PostCode
              ? { border: finalCheckedData.borderColors.PostCode }
              : {}
            : {}
        }
        value={finalValue.PostCode}
      />
      <input
        type="text"
        name={`City`}
        id={`${props.type}_city`}
        onChange={(e) => props.change(e, props.index)}
        placeholder="City *"
        required
        style={
          finalCheckedData?.borderColors !== undefined
            ? finalCheckedData.borderColors.City
              ? { border: finalCheckedData.borderColors.City }
              : {}
            : {}
        }
        value={finalValue.City}
      />
      <input
        type="input"
        name={`Phone`}
        id={`${props.type}_Phone`}
        maxLength="14"
        placeholder="Phone number"
        onChange={(e) => props.change(e, props.index)}
      />
      <textarea
        type="text"
        name={`Comments`}
        id={`${props.type}_Comments`}
        placeholder="Comments"
        onChange={(e) => props.change(e, props.index)}
      />
    </div>
  );
};

export default BookServiceForm;
