import React, {useContext} from "react";
import AboutUs from "../components/AboutUs";
// import HomeAnimation from "../components/HomeAnimation";
import ServiceCard from "../components/ServiceCard";
import Slider from "../components/Slider";
import {ProductContext} from "../context/ProductContext"
import "../scss/Home.scss";

const Home = () => {
  const {services} = useContext(ProductContext)
  return (
    <div className="homeContainer">
      <div className="slider">
        <Slider />
      </div>
      <div className="title">Our Services</div>
      <div className="service">
        {
          services?.map((service, index) => (<ServiceCard service={ service} key={index}/>))
        }
      </div>
      <div className="title">About Us</div>
      <div className="about">
        <AboutUs />
      </div>
    </div>
  );
};

export default Home;
