import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";
import { ClientContext } from "../context/ClientContext";
import { PartnerContext } from "../context/PartnerContext";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import "../scss/Navbar.scss";
import { logOut } from "../helpers/PartnerApi";
import MobileMenu from "./MobileMenu";

const Navbar = () => {
  const navigate = useNavigate();
  const { isLogged, isAdmin, setPartner, setIsLogged, partner, setIsAdmin } =
    useContext(PartnerContext);
  const { isClientLogged, clearClient } = useContext(ClientContext);
  const [positionRight] = useState(0);

  // console.log(isAdmin);
  const partnerLogout = () => {
    setPartner();
    logOut();
    setIsAdmin(false);
    setIsLogged(false);
    navigate("/");
  };
  const clientLogout = () => {
    clearClient();
    navigate("/");
  };

  return (
    <>
      <div className="navbarContainer">
        <div className="image">
          <Link to="/">
            <img src="../assets/logo.png" alt="Moving Service" />
          </Link>
        </div>
        <div className="menueItem">
          <a href="tel:+07769372911">
            <PhoneIcon /> 0000000000
          </a>
          <Link to="/packages">PRICING</Link>
          {!isClientLogged && !isLogged && (
            <>
              <Link to="/client/login">CLIENT LOGIN</Link>
              <Link to="/partner/login">PARTNER LOGIN</Link>
            </>
          )}

          {isAdmin && (
            <>
              <Link to="/admin/dashboard/partners">PARTNERS</Link>
              <Link to="/admin/dashboard/clients">CLIENTS</Link>
              <Link to="/admin/orders">ORDERS</Link>
              <Link to="/admin/packages">PACKAGES</Link>
              <Link to="/admin/services">SERVICES</Link>
              <Link to="/admin/accounts">ACCOUNTS</Link>
              <Link to="/admin/dashboard">ADMIN DASHBOARD</Link>
              <span>
                <ExitToAppIcon onClick={partnerLogout} />
              </span>
            </>
          )}

          {!isClientLogged && !isAdmin && isLogged && (
            <>
              <Link to="/partner/dashboard">PARTNER DASHBOARD</Link>
              <Link to={`/partner/profile/${partner._id}`}>PROFILE</Link>
              <Link to="/partner/orders">MY ORDERS</Link>
              <span>
                <ExitToAppIcon onClick={partnerLogout} />
              </span>
            </>
          )}

          {isClientLogged && !isLogged && (
            <>
              <Link to="/client/dashboard">CLIENT DASHBOARD</Link>
              <Link to="/">PROFILE</Link>
              <Link to="/">ADDRESS</Link>
              <span>
                <ExitToAppIcon onClick={clientLogout} />
              </span>
            </>
          )}
        </div>
      </div>
      <div className="mobileMenuContainer">
        <MobileMenu positionRight={positionRight} />
      </div>
    </>
  );
};

export default Navbar;
