import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_API_BASE_URL || `http://localhost:5000`;
axios.defaults.withCredentials = true;

const extractApiError = (errAxios) => {
  return errAxios.response
    ? errAxios.response.data
    : { error: { message: "API not reachable" } };
};


export const getBookings = async () => {
  try {
    const response = await axios.get(`/bookings`);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const getInvoices = async () => {
  try {
    const response = await axios.get(`/invoices`);
    return response.data;
  } catch (error) {
    return extractApiError(error);
  }
};

export const getBooking = async (id) => {
  try {
    const response = await axios.get(`/bookings/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrder = async (id) => {
  try {
    const response = await axios.get(`/orders/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const createOrder = async (data) => {
  try {
    const response = await axios.post(`/orders`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createBooking = async (data) => {
  // console.log(data);
  try {
    const response = await axios.post(`/bookings`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateBooking = async (id, data) => {
  try {
    const response = await axios.patch(`/bookings/${id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const removeOrder = async (id) => {
  try {
    const res = await axios.delete(`/orders/${id}`)
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export const createInvoice = async (data) => {
  try {
    const response = await axios.post(`/invoices`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};