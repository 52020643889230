import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import "../scss/Checkout.scss";
import { ClientContext } from "../context/ClientContext";
import {
  login,
  googleLogin,
  register,
  addBillAddress,
} from "../helpers/ClientApi";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FaceIcon from "@material-ui/icons/Face";
import PhoneIcon from "@material-ui/icons/Phone";
import WorkIcon from "@material-ui/icons/Work";
import { ProductContext } from "../context/ProductContext";
import { createBooking, removeOrder, getOrder } from "../helpers/OrderApi";

// import { PartnerContext } from "../context/PartnerContext";

// import { logOut } from "../helpers/PartnerApi"

const Checkout = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    client,
    setClient,
    storeClient,
    clearClient,
    avatar,
    setAvatar,
    setAvatarPreview,
    setClientError,
    // setIsClientLogged,
    callback,
    setCallback,
  } = useContext(ClientContext);
  // const { setPartner, setIsLogged } = useContext(PartnerContext);
  const { order, setOrder } = useContext(ProductContext);
  const [bookingData, setBookingData] = useState({});

  useEffect(() => {
    const get = async () => {
      try {
        const res = await getOrder(params.id);
        setBookingData(res);
        setOrder(res);
      } catch (error) {
        console.log(error);
      }
    };
    get();
  }, [params.id, setOrder]);

  const [showPassword, setShowPassword] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const loginTab = useRef(null);
  const registerTab = useRef(null);
  const switcherTab = useRef(null);

  const [form, setForm] = useState({
    CompanyName: "",
    Phone: "",
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
  });

  //need to update stare on load...not complete
  const [addressForm, setAddressForm] = useState({});

  const { CompanyName, FirstName, LastName, Email, Password, Phone } = form;

  const responseGoogle = async (response) => {
    const { email, familyName, givenName, googleId, imageUrl } =
      response.profileObj;
    const data = {
      Email: email,
      googleId,
      FirstName: givenName,
      LastName: familyName,
      Avatar: imageUrl,
    };
    let result = await googleLogin(data);
    if (result.error) {
      clearClient();
      return setClientError(result.error);
    }
    storeClient(result);
    setAddressForm(result.client.InvoiceAddress);
    setCallback(!callback);
    setClientError({});
  };

  const handleChange = (e) => {
    if (e.target.name === "Avatar") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const handleAddressChange = (e) => {
    setAddressForm({ ...addressForm, [e.target.name]: e.target.value });
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    const result = await login(form);
    if (result.error) {
      clearClient();
      return setClientError(result.error);
    }
    storeClient(result);
    setAddressForm(result.client.InvoiceAddress);
    setCallback(!callback);
    setClientError({});
  };

  const registerSubmit = async (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set("CompanyName", CompanyName);
    myForm.set("FirstName", FirstName);
    myForm.set("LastName", LastName);
    myForm.set("Email", Email);
    myForm.set("Phone", Phone);
    myForm.set("Password", Password);
    myForm.set("Avatar", avatar);

    const result = await register(myForm);
    if (result.error) {
      clearClient();
      return setClientError(result.error);
    }
    storeClient(result);
    setAddressForm(result.client.InvoiceAddress);
    setCallback(!callback);
    setClientError({});
  };

  const addressSubmit = async (e) => {
    e.preventDefault();
    const result = await addBillAddress(client._id, addressForm);
    if (result.error) {
      clearClient();
      return setClientError(result.error);
    }
    setClient(result);
    setCallback(!callback);
    setShowButton(false);
  };

  const switchTabs = (e, tab) => {
    if (tab === "login") {
      switcherTab.current.classList.add("shiftToNeutral");
      switcherTab.current.classList.remove("shiftToRight");

      registerTab.current.classList.remove("shiftToNeutralForm");
      loginTab.current.classList.remove("shiftToLeft");
    }
    if (tab === "register") {
      switcherTab.current.classList.add("shiftToRight");
      switcherTab.current.classList.remove("shiftToNeutral");

      registerTab.current.classList.add("shiftToNeutralForm");
      loginTab.current.classList.add("shiftToLeft");
    }
  };

  const bookedcash = async (data) => {
    try {
      await createBooking({
        ...bookingData,
        ClientId: data,
        Total: total,
        PaymentType: "CASH",
      });
      await removeOrder(params.id);
      navigate("/thankyou");
    } catch (error) {
      console.log(error);
    }
  };

  const bookedcard = async (data) => {
    try {
      await createBooking({
        ...bookingData,
        ClientId: data,
        Total: total,
        PaymentType: "CARD",
      });
      await removeOrder(params.id);
      navigate("/thankyou");
    } catch (error) {
      console.log(error);
    }
  };
  const bookedsepa = async (data) => {
    try {
      await createBooking({
        ...bookingData,
        ClientId: data,
        Total: total,
        PaymentType: "SEPA",
      });
      await removeOrder(params.id);
      navigate("/thankyou");
    } catch (error) {
      console.log(error);
    }
  };
  const bookedinv = async (data) => {
    try {
      await createBooking({
        ...bookingData,
        ClientId: data,
        Total: total,
        PaymentType: "INVOICE",
      });
      await removeOrder(params.id);
      navigate("/thankyou");
    } catch (error) {
      console.log(error);
    }
  };

  // *************************************
  const KmPrice = (order.PackageId?.PricePerKm * order.Total_KM).toFixed(2) * 1;
  const StopagePrice =
    (order.PackageId?.StoppagePrice * order.StopagesAddress?.length).toFixed(
      2
    ) * 1;
  let Netto =
    (order.PackageId?.PackagePrice + KmPrice + StopagePrice).toFixed(2) * 1;
  if (order.PackageId?.ServiceID === "60a5d302b16800349463a83a") {
    Netto =
      (order.PackageId?.PackagePrice + KmPrice + StopagePrice).toFixed(2) *
      1 *
      2;
  }
  let Vat = (Netto * (order.PackageId?.MwSt / 100)).toFixed(2) * 1;
  let total = (Netto + Vat).toFixed(2) * 1;

  // ******************************

  // console.log(client);
  // console.log(addressForm);
  return (
    <div className="checkoutContainer">
      <div className="header">Checkout Page</div>
      <div className="mainBody">
        <div className="bill">
          <h1>ESTIMATE BILL</h1>
          <div className="checkout-bill">
            <div className="line">
              <div>Package :</div>
              <div> {order.PackageId?.Title} </div>
            </div>
            <div className="line">
              <div>Date & Time :</div>
              <div>
                {" "}
                {order.BookingDate?.slice(0, 10) + ` At  ` + order.BookingTime}
              </div>
            </div>
            <div className="line">
              <div>Time :</div>
              <div> {order.BookingTime}</div>
            </div>
            <div className="line">
              <div>Base Price :</div>
              <div> {order.PackageId?.PackagePrice} £</div>
            </div>
            <div className="line">
              <div>Distance Price :</div>
              <div> {KmPrice} £</div>
            </div>
            <div className="line">
              <div>Stopage Price :</div>
              <div> {StopagePrice} £</div>
            </div>
            <div className="line">
              <div>Netto :</div>
              <div> {Netto} £</div>
            </div>
            <div className="line">
              <div>Vat :</div>
              <div> {Vat} £</div>
            </div>
            <div className="line">
              <div>Total :</div>
              <div> {total} £</div>
            </div>
          </div>
        </div>
        <div className="client">
          <h1> {client ? "INVOICE ADDRESS" : "REGISTRATION"}</h1>
          {!client ? (
            <div className="authBox">
              <div>
                <div className="authToggle">
                  <p onClick={(e) => switchTabs(e, "login")}>LOGIN</p>
                  <p onClick={(e) => switchTabs(e, "register")}>REGISTER</p>
                </div>
                <button ref={switcherTab}></button>
              </div>
              <GoogleLogin
                className="google-login"
                clientId="884341565533-nu6t3be9dr6q0bbm8c3n8v754hbjcohh.apps.googleusercontent.com"
                buttonText="USE GOOGLE"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
              <form className="loginForm" ref={loginTab} onSubmit={loginSubmit}>
                <div className="loginEmail">
                  <MailOutlineIcon />
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    name="Email"
                    value={form.Email}
                    onChange={handleChange}
                  />
                </div>
                <div className="loginPassword">
                  {showPassword ? (
                    <LockOpenIcon onClick={handleShowPassword} />
                  ) : (
                    <LockOutlinedIcon onClick={handleShowPassword} />
                  )}
                  <input
                    placeholder="Password"
                    required
                    name="Password"
                    value={form.Password}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                  />
                </div>
                <Link to="/password/forgot">Forget Password ?</Link>
                <input type="submit" value="Login" className="loginBtn" />
              </form>
              <form
                className="signUpForm"
                ref={registerTab}
                encType="multipart/form-data"
                onSubmit={registerSubmit}
              >
                <div className="signUpName">
                  <WorkIcon />
                  <input
                    type="text"
                    placeholder="Company Name"
                    required
                    name="CompanyName"
                    value={CompanyName}
                    onChange={handleChange}
                  />
                </div>
                <div className="signUpName">
                  <FaceIcon />
                  <input
                    type="text"
                    placeholder="First Name *"
                    required
                    name="FirstName"
                    value={FirstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="signUpName">
                  <FaceIcon />
                  <input
                    type="text"
                    placeholder="Family Name *"
                    required
                    name="LastName"
                    value={LastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="signUpName">
                  <PhoneIcon />
                  <input
                    type="text"
                    placeholder="Mobile Number *"
                    required
                    name="Phone"
                    value={Phone}
                    onChange={handleChange}
                    maxLength={15}
                  />
                </div>
                <div className="signUpEmail">
                  <MailOutlineIcon />
                  <input
                    type="email"
                    placeholder="Email *"
                    required
                    name="Email"
                    value={Email}
                    onChange={handleChange}
                  />
                </div>
                <div className="signUpPassword">
                  {showPassword ? (
                    <LockOpenIcon onClick={handleShowPassword} />
                  ) : (
                    <LockOutlinedIcon onClick={handleShowPassword} />
                  )}
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password *"
                    required
                    name="Password"
                    value={Password}
                    onChange={handleChange}
                  />
                </div>
                <input type="submit" value="Register" className="signUpBtn" />
              </form>
            </div>
          ) : (
            <form
              className="billForm"
              encType="multipart/form-data"
              onSubmit={addressSubmit}
            >
              <div className="signUpName">
                <input
                  type="text"
                  placeholder="Company Name"
                  name="BusinessName"
                  value={addressForm?.BusinessName}
                  onChange={handleAddressChange}
                  onClick={() => setShowButton(true)}
                />
              </div>
              <div className="signUpName">
                <input
                  type="text"
                  placeholder="Name *"
                  required
                  name="Name"
                  value={addressForm?.Name}
                  onChange={handleAddressChange}
                  onClick={() => setShowButton(true)}
                />
              </div>
              <div className="signUpName">
                <input
                  type="text"
                  placeholder="House Number *"
                  required
                  name="HouseNumber"
                  value={addressForm?.HouseNumber}
                  onChange={handleAddressChange}
                  onClick={() => setShowButton(true)}
                />
              </div>
              <div className="signUpName">
                <input
                  type="text"
                  placeholder="Street *"
                  required
                  name="Street"
                  value={addressForm?.Street}
                  onChange={handleAddressChange}
                  onClick={() => setShowButton(true)}
                />
              </div>
              <div className="signUpName">
                <input
                  type="text"
                  placeholder="Post Code *"
                  required
                  name="PostCode"
                  value={addressForm?.PostCode}
                  onChange={handleAddressChange}
                  onClick={() => setShowButton(true)}
                />
              </div>
              <div className="signUpName">
                <input
                  type="text"
                  placeholder="City *"
                  required
                  name="City"
                  value={addressForm?.City}
                  onChange={handleAddressChange}
                  onClick={() => setShowButton(true)}
                />
              </div>
              {showButton && (
                <div className="signUpName">
                  <input type="submit" value="Next" className="signUpBtn" />
                </div>
              )}
            </form>
          )}
          {!showButton && (
            <div className="payment-btn">
              <h1>PAYMENT OPTIONS</h1>
              <div
                className="btncontainer"
                onClick={() => bookedcard(client._id)}
              >
                <div className="interactive-container">
                  <div className="card">
                    <div className="card-line"></div>
                    <div className="card-circles">
                      <div className="card-circle"></div>
                      <div className="card-circle"></div>
                      <div className="card-circle"></div>
                    </div>
                  </div>
                  <div className="post">
                    <div className="post-line"></div>
                    <div className="post-screen">
                      <span className="post-text">$</span>
                    </div>
                    <div className="post-numbers">
                      <div className="post-number"></div>
                      <div className="post-number"></div>
                      <div className="post-number"></div>
                      <div className="post-number"></div>
                      <div className="post-number"></div>
                      <div className="post-number"></div>
                      <div className="post-number"></div>
                      <div className="post-number"></div>
                      <div className="post-number"></div>
                    </div>
                  </div>
                </div>
                <div className="text-container">Pay By Card</div>
              </div>
              <button onClick={() => bookedsepa(client._id)}>
                Bank Transfer
              </button>
              <button onClick={() => bookedcash(client._id)}>
                Pay In Cash
              </button>
              {client.InvoiceAddress?.BusinessName && (
                <button onClick={() => bookedinv(client._id)}>
                  On Invoice
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
