import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import ClientContextProvider from "./context/ClientContext.jsx";
import ProductContextProvider from "./context/ProductContext.jsx";
import PartnerContextProvider from "./context/PartnerContext";

ReactDOM.render(
  <PartnerContextProvider>
    <ClientContextProvider>
      <ProductContextProvider>
        <App />
      </ProductContextProvider>
    </ClientContextProvider>
  </PartnerContextProvider>,
  document.getElementById("root")
);
