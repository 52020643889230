import React from "react";
import { Link } from "react-router-dom";
import "../scss/PricingCard.scss";

const PricingCard = ({ itemData }) => {
  //console.log(itemData);
  return (
    <Link className="pricingCard" to={`/booking-progress/${itemData._id}`}>
      <div className="price-title">{itemData.Title}</div>
      <div className="pricing-content">
        <p dangerouslySetInnerHTML={{ __html: itemData.BigDetails }}></p>
      </div>
      <div className="pricingFooter">
        <span>* All Prices are excluding Tax</span>
        <span>* Payment after completion job</span>
      </div>
    </Link>
  );
};

export default PricingCard;
