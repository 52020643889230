import React from "react";
import { useContext } from "react";
// import { Link, useNavigate } from "react-router-dom";
import { PartnerContext } from "../../context/PartnerContext";
import "../../scss/PartnerProfile.scss";

const PartnerProfile = () => {
  // const navigate = useNavigate();
  const { partner } = useContext(PartnerContext);
  // console.log(partner);

  return (
    <div className="profileContainer">
      <div className="partnerProfile">
        <img
          src={partner?.Avatar.url}
          alt={partner.FirstName + " " + partner.FamilyName}
        />
        <h4 style={{ fontSize: "1.5vmax" }}>
          {partner.FirstName + " " + partner.FamilyName}
        </h4>
        <h4>
          Email : <span>{partner.Email}</span>
        </h4>
        <h4>
          Tel : <span>{partner.MobileNumber}</span>
        </h4>
        <h4>
          Joined on : <span>{String(partner.createdAt).slice(0, 10)}</span>
        </h4>
        <h4>
          Status : <span>{partner.Status}</span>
        </h4>
      </div>
      <div className="details">
        <div>
          <h4>Salutation : </h4>
          <p>{partner.Salutation}</p>
        </div>
        <div>
          <h4>First Name : </h4>
          <p>{partner.FirstName}</p>
        </div>
        <div>
          <h4>Family Name : </h4>
          <p>{partner.FamilyName}</p>
        </div>
        <div>
          <h4>Commission : </h4>
          <p>{partner.Commission} %</p>
        </div>
        <div>
          <h4>Street : </h4>
          <p>{partner.Street}</p>
        </div>
        <div>
          <h4>HouseNumber : </h4>
          <p>{partner.HouseNumber}</p>
        </div>
        <div>
          <h4>PostCode : </h4>
          <p>{partner.PostCode}</p>
        </div>
        <div>
          <h4>City : </h4>
          <p>{partner.City}</p>
        </div>
        <div>
          <h4>Country : </h4>
          <p>{partner.Country}</p>
        </div>
        <div>
          <h4>InterestedWorkingAreas : </h4>
          <p>{partner.InterestedWorkingAreas}</p>
        </div>
        <div>
          <h4>Nationality : </h4>
          <p>{partner.Nationality}</p>
        </div>
        <div>
          <h4>DOB : </h4>
          <p>{partner.DOB}</p>
        </div>
        <div>
          <h4>IdPassNumber : </h4>
          <p>{partner.IdPassNumber}</p>
        </div>
        <div>
          <h4>Validity : </h4>
          <p>{partner.Validity}</p>
        </div>
        <div>
          <h4>InterestedWorkingField : </h4>
          <p>{partner.InterestedWorkingField}</p>
        </div>
        <div>
          <h4>RegisterAs : </h4>
          <p>{partner.RegisterAs}</p>
        </div>
        <div>
          <h4>LanguageSkills : </h4>
          <p>{partner.LanguageSkills}</p>
        </div>
        <div>
          <h4>IdPassportFront : </h4>
          <p>pic</p>
        </div>
        <div>
          <h4>IdPassportBack : </h4>
          <p>{partner.IdPassportBack} pic</p>
        </div>
        <div>
          <h4>BusinessRegistration : </h4>
          <p>{partner.BusinessRegistration} pic</p>
        </div>
        <div>
          <h4>PoliceClearence : </h4>
          <p>{partner.PoliceClearence} pic</p>
        </div>
        <div>
          <h4>TaxIdFile : </h4>
          <p>{partner.TaxIdFile} pic</p>
        </div>
        <div>
          <h4>IdentificationNumber : </h4>
          <p>{partner.IdentificationNumber} %</p>
        </div>
        <div>
          <h4>TaxId : </h4>
          <p>{partner.TaxId}</p>
        </div>
        <div>
          <h4>BankIBAN : </h4>
          <p>{partner.BankIBAN}</p>
        </div>

        <div>
          <h4>City : </h4>
          <p>{partner.City}</p>
        </div>
        <div>
          <h4>Country : </h4>
          <p>{partner.Country}</p>
        </div>
        <div>
          <h4>InterestedWorkingAreas : </h4>
          <p>{partner.InterestedWorkingAreas}</p>
        </div>
        <div>
          <h4>Nationality : </h4>
          <p>{partner.Nationality}</p>
        </div>
        <div>
          <h4>DOB : </h4>
          <p>{partner.DOB}</p>
        </div>
        <div>
          <h4>IdPassNumber : </h4>
          <p>{partner.IdPassNumber}</p>
        </div>
        <div>
          <h4>Validity : </h4>
          <p>{partner.Validity}</p>
        </div>
        <div>
          <h4>InterestedWorkingField : </h4>
          <p>{partner.InterestedWorkingField}</p>
        </div>
      </div>
    </div>
  );
};

export default PartnerProfile;
