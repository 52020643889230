import React, { useContext } from "react";
import "./scss/App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { PartnerContext } from "./context/PartnerContext";
import { ClientContext } from "./context/ClientContext";
import { ProductContext } from "./context/ProductContext";

import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import PartnerAuth from "./components/partners/PartnerAuth";
import ClientAuth from "./components/clients/ClientAuth";
import PartnerDashboard from "./components/partners/PartnerDashboard.jsx";
import Loading from "./components/Loading.jsx";
import ClientDashboard from "./components/clients/ClientDashboard";
import ErrorDisplay from "./components/ErrorDisplay";
import PartnerProfile from "./components/partners/PartnerProfile";
import Pricing from "./pages/Pricing";
import Error404 from "./components/Error404";
import BookingProgress from "./pages/BookingProgress.jsx";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import Service from "./pages/Service";
import AdminDashboard from "./components/admin/AdminDashboard";
import Clients from "./components/admin/Clients";
import Partners from "./components/admin/Partners";
import PartnerDetails from "./components/admin/PartnerDetails";
import Checkout from "./pages/Checkout";
import ThankYou from "./components/ThankYou";
import PartnerOrders from "./components/partners/PartnerOrders";
import Print from "./components/Print";
import OrderDetails from "./components/OrderDetails";
import HomeAnimation from "./components/HomeAnimation";
import Accounting from "./components/admin/Accounting";
import ClientInfo from "./components/admin/ClientInfo";
import Orders from "./components/admin/Orders";

const App = () => {
  const { isLogged, isAdmin } = useContext(PartnerContext);
  const { isClientLogged } = useContext(ClientContext);
  const { loading } = useContext(ProductContext);

  if (loading === true) {
    return <Loading />;
  }

  return (
    <Router>
      <Navbar />
      <ErrorDisplay />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/checkout/:id" element={<Checkout />} />
        <Route path="/test" element={<HomeAnimation />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/packages" element={<Pricing />} />
        <Route path="/services/:id" element={<Service />} />
        <Route path="/booking-progress/:id" element={<BookingProgress />} />
        <Route path="/partner/dashboard" element={<PartnerDashboard />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/dashboard/clients" element={<Clients />} />
        <Route path="/admin/client/details/info/:id" element={<ClientInfo />} />
        <Route path="/admin/dashboard/partners" element={<Partners />} />
        <Route path="/admin/accounts" element={<Accounting />} />
        <Route path="/admin/orders" element={<Orders />} />
        {/* <Route path="/admin/accounts" element={<Accounting />} />
        <Route path="/admin/accounts" element={<Accounting />} /> */}
        <Route path="/print/:id" element={<Print />} />
        <Route path="/job-details/:id" element={<OrderDetails />} />
        <Route
          path="/admin/dashboard/partners/:id"
          element={<PartnerDetails />}
        />
        {isLogged ? (
          <Route
            path="/partner/login"
            element={
              <Navigate
                replace
                to={isAdmin ? "/admin/dashboard" : "/partner/dashboard"}
              />
            }
          />
        ) : (
          <Route path="/partner/login" element={<PartnerAuth />} />
        )}

        {isLogged ? (
          <Route path="/partner/profile/:id" element={<PartnerProfile />} />
        ) : (
          <Route path="/partner/login" element={<PartnerAuth />} />
        )}

        {isLogged ? (
          <Route path="/partner/orders" element={<PartnerOrders />} />
        ) : (
          <Route path="/partner/login" element={<PartnerAuth />} />
        )}

        {isClientLogged ? (
          <Route path="/client/login" element={<Navigate replace to="/" />} />
        ) : (
          <Route path="/client/login" element={<ClientAuth />} />
        )}
        {isClientLogged ? (
          <Route path="/client/dashboard" element={<ClientDashboard />} />
        ) : (
          <Route
            path="/client/dashboard"
            element={<Navigate replace to="/client/login" />}
          />
        )}
        <Route path="/*" element={<Error404 />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
