import React, { Fragment, useState, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FaceIcon from "@material-ui/icons/Face";
import PhoneIcon from "@material-ui/icons/Phone";
import WorkIcon from "@material-ui/icons/Work";
import { GoogleLogin } from "react-google-login";
import { ClientContext } from "../../context/ClientContext";
import { login, googleLogin, register } from "../../helpers/ClientApi";
import "../../scss/Auth.scss";
import Loading from "../Loading";

const ClientAuth = () => {
  const navigate = useNavigate();

  const {
    storeClient,
    clearClient,
    avatar,
    setAvatar,
    avatarPreview,
    setAvatarPreview,
    setClientError,
    setIsClientLogged,
    setLoading,
    loading,
  } = useContext(ClientContext);

  const a = Math.ceil(Math.random() * 255);
  const b = Math.ceil(Math.random() * 255);
  const c = Math.ceil(Math.random() * 255);
  const number = Number(`${c + b}${a}`);

  const success = (data) => {
    storeClient(data);
    setLoading(false);
    setIsClientLogged(true);
    setClientError({});
    navigate("/client/dashboard");
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const loginTab = useRef(null);
  const registerTab = useRef(null);
  const switcherTab = useRef(null);

  const [form, setForm] = useState({
    CompanyName: "",
    Phone: "",
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
  });

  const { CompanyName, FirstName, LastName, Email, Password, Phone } = form;

  const responseGoogle = async (response) => {
    const { email, familyName, givenName, googleId, imageUrl } =
      response.profileObj;
    const data = {
      Email: email,
      googleId,
      FirstName: givenName,
      LastName: familyName,
      Avatar: imageUrl,
    };
    const promptInput = Number(prompt(`Please Type  ${number}  Bellow: `));
    if (number === promptInput) {
      setLoading(true);
      const result = await googleLogin(data);
      if (result.error) {
        clearClient();
        return setClientError(result.error);
      }
      success(result);
    } else {
      alert(`Validation failed, try again!`);
    }
  };

  const handleChange = (e) => {
    setClientError({});
    if (e.target.name === "Avatar") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    setClientError({});
    const promptInput = Number(prompt(`Please Type  ${number}  Bellow: `));
    if (number === promptInput) {
      const result = await login(form);
      if (result.error) {
        clearClient();
        return setClientError(result.error);
      }
      success(result);
    } else {
      alert(`Validation failed, try again!`);
    }
  };

  const registerSubmit = async (e) => {
    e.preventDefault();
    setClientError({});
    const myForm = new FormData();
    myForm.set("CompanyName", CompanyName);
    myForm.set("FirstName", FirstName);
    myForm.set("LastName", LastName);
    myForm.set("Email", Email);
    myForm.set("Phone", Phone);
    myForm.set("Password", Password);
    myForm.set("Avatar", avatar);

    const result = await register(myForm);
    if (result.error) {
      clearClient();
      return setClientError(result.error);
    }
    success(result);
  };

  const switchTabs = (e, tab) => {
    if (tab === "login") {
      switcherTab.current.classList.add("shiftToNeutral");
      switcherTab.current.classList.remove("shiftToRight");

      registerTab.current.classList.remove("shiftToNeutralForm");
      loginTab.current.classList.remove("shiftToLeft");
    }
    if (tab === "register") {
      switcherTab.current.classList.add("shiftToRight");
      switcherTab.current.classList.remove("shiftToNeutral");

      registerTab.current.classList.add("shiftToNeutralForm");
      loginTab.current.classList.add("shiftToLeft");
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="authContainer">
          <div className="authBox">
            <div>
              <div className="authToggle">
                <p onClick={(e) => switchTabs(e, "login")}>LOGIN</p>
                <p onClick={(e) => switchTabs(e, "register")}>REGISTER</p>
              </div>
              <button ref={switcherTab}></button>
            </div>
            <GoogleLogin
              className="google-login"
              clientId="884341565533-nu6t3be9dr6q0bbm8c3n8v754hbjcohh.apps.googleusercontent.com"
              buttonText="USE GOOGLE"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
            <form className="loginForm" ref={loginTab} onSubmit={loginSubmit}>
              <div className="loginEmail">
                <MailOutlineIcon />
                <input
                  type="email"
                  placeholder="Email"
                  required
                  name="Email"
                  value={form.Email}
                  onChange={handleChange}
                />
              </div>
              <div className="loginPassword">
                {showPassword ? (
                  <LockOpenIcon onClick={handleShowPassword} />
                ) : (
                  <LockOutlinedIcon onClick={handleShowPassword} />
                )}
                <input
                  placeholder="Password"
                  required
                  name="Password"
                  value={form.Password}
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                />
              </div>
              <Link to="/password/forgot">Forget Password ?</Link>
              <input type="submit" value="Login" className="loginBtn" />
            </form>
            <form
              className="signUpForm"
              ref={registerTab}
              encType="multipart/form-data"
              onSubmit={registerSubmit}
            >
              <div className="signUpName">
                <WorkIcon />
                <input
                  type="text"
                  placeholder="Company Name"
                  required
                  name="CompanyName"
                  value={CompanyName}
                  onChange={handleChange}
                />
              </div>
              <div className="signUpName">
                <FaceIcon />
                <input
                  type="text"
                  placeholder="First Name *"
                  required
                  name="FirstName"
                  value={FirstName}
                  onChange={handleChange}
                />
              </div>
              <div className="signUpName">
                <FaceIcon />
                <input
                  type="text"
                  placeholder="Family Name *"
                  required
                  name="LastName"
                  value={LastName}
                  onChange={handleChange}
                />
              </div>
              <div className="signUpName">
                <PhoneIcon />
                <input
                  type="text"
                  placeholder="Mobile Number *"
                  required
                  name="Phone"
                  value={Phone}
                  onChange={handleChange}
                  maxLength={15}
                />
              </div>
              <div className="signUpEmail">
                <MailOutlineIcon />
                <input
                  type="email"
                  placeholder="Email *"
                  required
                  name="Email"
                  value={Email}
                  onChange={handleChange}
                />
              </div>
              <div className="signUpPassword">
                {showPassword ? (
                  <LockOpenIcon onClick={handleShowPassword} />
                ) : (
                  <LockOutlinedIcon onClick={handleShowPassword} />
                )}
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password *"
                  required
                  name="Password"
                  value={Password}
                  onChange={handleChange}
                />
              </div>

              <div className="registerImage">
                <img src={avatarPreview} alt="Avatar Preview" />
                <input
                  type="file"
                  name="Avatar"
                  accept="image/*"
                  onChange={handleChange}
                />
              </div>
              <input type="submit" value="Register" className="signUpBtn" />
            </form>
          </div>
        </div>
      )}
    </>
  );
};
export default ClientAuth;
