import React, { useContext, useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ReactPaginate from "react-paginate";
import { ProductContext } from "../../context/ProductContext";
import "../../scss/Accounting.scss";
import { useNavigate } from "react-router-dom";

const Accounting = () => {
  const navigate = useNavigate();
  const { bookings } = useContext(ProductContext);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [sortItems, setSortItems] = useState([]);

  useEffect(() => {
    const filtedItems = [];
    bookings.forEach((order) => {
      if (order.BookingStatus === "Accomplished") {
        filtedItems.push(order);
      }
      return order;
    });
    setSortItems(filtedItems);
  }, [bookings]);

  // Sorting by Payment Type

  const handleSort = (data) => {
    const Items = [];
    bookings.map((item) => {
      if (item.BookingStatus === "Accomplished" && data === "All") {
        Items.push(item);
      }
      if (item.BookingStatus === "Accomplished" && data === item.PaymentType) {
        Items.push(item);
      }
      return Items;
    });
    setSortItems(Items);
  };

  const handleClick = () => {
    console.log("Clicked");
    const items = [];
    bookings.forEach((item) => {
      if (fromDate > toDate) {
        return items;
      } else {
        if (fromDate !== undefined && toDate !== undefined) {
          if (
            item.BookingStatus === "Accomplished" &&
            item.BookingDate.slice(0, 10) >= fromDate &&
            item.BookingDate.slice(0, 10) <= toDate
          ) {
            items.push(item);
          }
        } else if (fromDate !== undefined) {
          if (
            item.BookingStatus === "Accomplished" &&
            item.BookingDate.slice(0, 10) >= fromDate
          ) {
            items.push(item);
          }
        } else if (toDate !== undefined) {
          if (
            item.BookingStatus === "Accomplished" &&
            item.BookingDate.slice(0, 10) <= toDate
          ) {
            items.push(item);
          }
        } else {
          items.push(item);
        }
      }
      return items;
    });
    setSortItems(items);
  };

  const goTo = (data) => {
    navigate(`/print/${data}`);
  };

  // console.log("from", fromDate);
  // console.log("to", toDate);

  // Pagination

  const [pageNumber, setPageNumber] = useState(0);

  const ordersPerPage = 5;
  const pagesVisited = pageNumber * ordersPerPage;
  const pageCount = Math.ceil(sortItems.length / ordersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Total Value

  let orderTotalArrays = [];
  let paidOutArrays = [];
  let grossTotalArrays = [];
  let vatTotalArrays = [];
  let netTotalArrays = [];
  sortItems
    .slice(pagesVisited, pagesVisited + ordersPerPage)
    .filter((item) => {
      if (search === "") {
        return item;
      }
      if (item.ClientId.FullName?.toLowerCase().includes(search)) {
        return item;
      }
      if (item.PartnerId?.FullName.toLowerCase().includes(search)) {
        return item;
      } else {
        return null;
      }
    })
    .forEach((item) => {
      const basePrice = item.PackageId?.PackagePrice;
      const KmPrice =
        (item.PackageId?.PricePerKm * item.Total_KM).toFixed(2) * 1;
      const StopagePrice =
        (item.PackageId?.StoppagePrice * item.StopagesAddress?.length).toFixed(
          2
        ) * 1;
      const loadingPrice = (item.Load_Unload / 5) * item.PackageId?.Price5min;
      const helpingPrice =
        (item.Load_Unload_Help / 60) * item.PackageId?.PricePerHelper;
      const Netto =
        (
          basePrice +
          KmPrice +
          StopagePrice +
          loadingPrice +
          helpingPrice
        ).toFixed(2) * 1;
      const Vat = (Netto * (item.PackageId?.MwSt / 100)).toFixed(2) * 1;
      const orderTotal = (Netto + Vat).toFixed(2) * 1;
      orderTotalArrays.push(orderTotal);
      const paidOut =
        (orderTotal - orderTotal * (item.PartnerId?.Commission / 100)).toFixed(
          2
        ) * 1;
      paidOutArrays.push(paidOut);
      const grosstoCompany = (orderTotal - paidOut).toFixed(2) * 1;
      grossTotalArrays.push(grosstoCompany);
      const vat = ((grosstoCompany / 119) * 19).toFixed(2) * 1;
      vatTotalArrays.push(vat);
      const net = (grosstoCompany - vat).toFixed(2) * 1;
      netTotalArrays.push(net);
    });

  const totalSum =
    orderTotalArrays
      ?.reduce(
        (acc, curr) => {
          return acc + curr;
        },
        0,
        0
      )
      .toFixed(2) * 1;
  const paidSum =
    paidOutArrays
      ?.reduce((acc, curr) => {
        return acc + curr;
      }, 0)
      .toFixed(2) * 1;
  const groseSum =
    grossTotalArrays
      ?.reduce((acc, curr) => {
        return acc + curr;
      }, 0)
      .toFixed(2) * 1;
  const vatSum =
    vatTotalArrays
      ?.reduce((acc, curr) => {
        return acc + curr;
      }, 0)
      .toFixed(2) * 1;
  const netSum =
    netTotalArrays
      ?.reduce((acc, curr) => {
        return acc + curr;
      }, 0)
      .toFixed(2) * 1;

  return (
    <div className="admin-account-view">
      <div className="view-order">
        <h1>Kurier 247 Account Summery</h1>
        <div className="search-filter">
          <div className="select-date">
            <input
              type="date"
              name="from"
              id="from"
              onChange={(e) => setFromDate(e.target.value)}
            />
            <input
              type="date"
              name="to"
              id="to"
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="text-input">
            <input
              type="text"
              value={search}
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <select
              onChange={(e) => {
                handleSort(e.target.value);
              }}
            >
              <option value="All">All</option>
              <option value="CASH">Cash Pay</option>
              <option value="CARD">Card Payment</option>
              <option value="SEPA">Sepa Payment</option>
              <option value="INVOICE">Invoice Pay</option>
            </select>
          </div>
          <div className="submit-button">
            <button type="submit" onClick={handleClick}>
              Search
            </button>
            <button type="button">Export</button>
          </div>
        </div>

        <Table>
          <Thead>
            <Tr>
              <Th>Order Id</Th>
              <Th>Order Status</Th>
              <Th>Partner</Th>
              <Th>Client</Th>
              <Th>Package</Th>
              <Th>Booking Date</Th>
              <Th>Booking Time</Th>
              <Th>Payment Type</Th>
              <Th>Payment Status</Th>
              <Th>Order Total</Th>
              <Th>PaidOut</Th>
              <Th>Gross Total</Th>
              <Th>Mwst19%</Th>
              <Th>Netto</Th>
              <Th>Print</Th>
              {/* <Th>Details</Th> */}
            </Tr>
          </Thead>
          {sortItems
            .slice(pagesVisited, pagesVisited + ordersPerPage)
            .filter((item, index) => {
              if (search === "") {
                return item;
              }
              if (
                item.ClientId.FullName?.toLowerCase().includes(
                  search.toLowerCase()
                )
              ) {
                return item;
              }
              if (
                item.PartnerId?.FullName.toLowerCase().includes(
                  search.toLowerCase()
                )
              ) {
                return item;
              } else {
                return null;
              }
            })
            .map((item, index) => {
              // console.log(item);
              const basePrice = item.PackageId?.PackagePrice;
              const KmPrice =
                (item.PackageId?.PricePerKm * item.Total_KM).toFixed(2) * 1;
              const StopagePrice =
                (
                  item.PackageId?.StoppagePrice * item.StopagesAddress?.length
                ).toFixed(2) * 1;
              const loadingPrice =
                (item.Load_Unload / 5) * item.PackageId?.Price5min;
              const helpingPrice =
                (item.Load_Unload_Help / 60) * item.PackageId?.PricePerHelper;
              const Netto =
                (
                  basePrice +
                  KmPrice +
                  StopagePrice +
                  loadingPrice +
                  helpingPrice
                ).toFixed(2) * 1;
              const Vat = (Netto * (item.PackageId?.MwSt / 100)).toFixed(2) * 1;
              const orderTotal = (Netto + Vat).toFixed(2) * 1;
              const paidOut =
                (
                  orderTotal -
                  orderTotal * (item.PartnerId?.Commission / 100)
                ).toFixed(2) * 1;
              const grosstoCompany = (orderTotal - paidOut).toFixed(2) * 1;
              const vat = ((grosstoCompany / 119) * 19).toFixed(2) * 1;
              const net = (grosstoCompany - vat).toFixed(2) * 1;
              return (
                <Tbody key={index}>
                  <Tr>
                    <Td>{item.InvoiceNumber}</Td>
                    <Td>{item.BookingStatus}</Td>
                    <Td>{item.PartnerId?.FullName}</Td>
                    <Td>{item.ClientId?.FullName}</Td>
                    <Td>{item.PackageId?.Title}</Td>
                    <Td>{item.BookingDate.slice(0, 10)} </Td>
                    <Td>{item.BookingTime}</Td>
                    <Td>{item.PaymentType} </Td>
                    <Td>{item.PaymentStatus}</Td>
                    <Td>{orderTotal} £</Td>
                    <Td>{paidOut} £</Td>
                    <Td>{grosstoCompany} £</Td>
                    <Td>{vat} £</Td>
                    <Td>{net} £</Td>
                    <Td>
                      <button onClick={() => goTo(item._id)} type="button">
                        VIEW
                      </button>
                    </Td>
                  </Tr>
                </Tbody>
              );
            })}
          <tfoot>
            <Tr>
              <Td colSpan="9">Total</Td>
              <Td>{totalSum} £</Td>
              <Td>{paidSum} £</Td>
              <Td>{groseSum} £ </Td>
              <Td>{vatSum} £ </Td>
              <Td>{netSum} £ </Td>
            </Tr>
          </tfoot>
        </Table>
        {sortItems.length > ordersPerPage && (
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            breakLabel={"..."}
          />
        )}
      </div>
    </div>
  );
};

export default Accounting;
