import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PartnerContext } from "../../context/PartnerContext";
import { getPartnerDetails, updatePartner } from "../../helpers/PartnerApi";
import "../../scss/PartnerDetails.scss";

const PartnerDetails = () => {
  const { id } = useParams();
  const { partner, setPartner } = useContext(PartnerContext);
  const [formData, setFormData] = useState(partner);

  useEffect(() => {
    const getDetails = async () => {
      const res = await getPartnerDetails(id);
      setPartner(res);
    };
    getDetails();
  }, [id, setPartner]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const {
    FirstName,
    FamilyName,
    Email,
    MobileNumber,
    Password,
    Street,
    HouseNumber,
    PostCode,
    City,
    Country,
    InterestedWorkingAreas,
    Nationality,
    DOB,
    IdPassNumber,
    Validity,
    Commission,
    Role,
    Status,
    TaxId,
  } = formData;

  const formSubmit = async (e) => {
    e.preventDefault();
    const result = await updatePartner(partner._id, formData);
    // console.log(result);
    setPartner(result);
  };

  // console.log(formData);

  return (
    <div className="partnerDetailsContainer">
      Partner Details
      <form
        className="signUpForm"
        encType="multipart/form-data"
        onSubmit={formSubmit}
      >
        <div className="signUpName">
          <input
            type="text"
            placeholder="First Name"
            required
            name="FirstName"
            value={FirstName}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="text"
            placeholder="Family Name"
            required
            name="FamilyName"
            value={FamilyName}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="text"
            placeholder="Mobile Number"
            required
            name="MobileNumber"
            value={MobileNumber}
            onChange={handleChange}
            maxLength={15}
          />
        </div>
        <div className="signUpEmail">
          <input
            type="email"
            placeholder="Email"
            required
            name="Email"
            value={Email}
            onChange={handleChange}
          />
        </div>
        <div className="signUpPassword">
          <input
            type="password"
            placeholder="Password"
            name="Password"
            value={Password}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="text"
            placeholder="Street"
            name="Street"
            value={Street}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="text"
            placeholder="House Number"
            name="HouseNumber"
            value={HouseNumber}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="text"
            placeholder="Post Code"
            name="PostCode"
            value={PostCode}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="text"
            placeholder="City"
            name="City"
            value={City}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="text"
            placeholder="Country"
            name="Country"
            value={Country}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="text"
            placeholder="Nationality"
            name="Nationality"
            value={Nationality}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          {/* <label htmlFor="">{DOB}</label> */}
          <input
            type="date"
            placeholder="DOB"
            name="DOB"
            value={DOB}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="text"
            placeholder="IdPassNumber"
            name="IdPassNumber"
            value={IdPassNumber}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="date"
            placeholder="Validity"
            name="Validity"
            value={Validity}
            onChange={handleChange}
          />
        </div>
        <div className="signUpName">
          <input
            type="number"
            placeholder="Commission"
            name="Commission"
            value={Commission}
            onChange={handleChange}
          />
        </div>{" "}
        <div className="signUpName">
          <input
            type="number"
            placeholder="Role"
            name="Role"
            value={Role}
            onChange={handleChange}
          />
        </div>{" "}
        <div className="signUpName">
          <select name="Status" onChange={handleChange}>
            <option defaultValue={Status}>{Status}</option>
            <option
              value="Pending"
              style={
                partner?.Status === "Pending"
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              Pending
            </option>
            <option
              value="Active"
              style={
                partner?.Status === "Active"
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              Active
            </option>
            <option
              value="Suspend"
              style={
                partner?.Status === "Suspend"
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              Suspend
            </option>
          </select>
        </div>{" "}
        <div className="signUpName">
          <input
            type="text"
            placeholder="TaxId"
            name="TaxId"
            value={TaxId}
            onChange={handleChange}
          />
        </div>{" "}
        <div className="signUpName">
          <input
            type="text"
            placeholder="InterestedWorkingAreas"
            name="InterestedWorkingAreas"
            value={InterestedWorkingAreas}
            onChange={handleChange}
          />
        </div>{" "}
        {/* <div className="registerImage">
          <img src={avatarPreview} alt="Avatar Preview" />
          <input
            type="file"
            name="Avatar"
            accept="image/*"
            onChange={handleChange}
          />
        </div> */}
        <input type="submit" value="UPDATE" className="signUpBtn" />
      </form>
    </div>
  );
};

export default PartnerDetails;
