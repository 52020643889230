import React from "react";
export default class ComponentToPrint extends React.Component {
  render(props) {
    // const invoice = this.props.number
    const order = this.props.reqOrder;
    const date = new Date(order.BookingDate);
    const localDate = date.toLocaleDateString();

    const basePrice = order.PackageId?.PackagePrice;
    const KmPrice =
      (order.PackageId?.PricePerKm * order.Total_KM).toFixed(2) * 1;
    const StopagePrice =
      (order.PackageId?.StoppagePrice * order.StopagesAddress?.length).toFixed(
        2
      ) * 1;
    const loadingPrice = (order.Load_Unload / 5) * order.PackageId?.Price5min;
    const helpingPrice =
      (order.Load_Unload_Help / 60) * order.PackageId?.PricePerHelper;
    const Netto =
      (
        basePrice +
        KmPrice +
        StopagePrice +
        loadingPrice +
        helpingPrice
      ).toFixed(2) * 1;
    const Vat = (Netto * (order.PackageId?.MwSt / 100)).toFixed(2) * 1;
    const total = (Netto + Vat).toFixed(2) * 1;

    // console.log(order);
    return (
      <div className="invoice-pdf">
        <div className="logo">
          <img src="../assets/logo.png" alt="KURIER 247" />
        </div>
        <div className="invoice-from">
          <div className="invoice mb-2">
            <h2>Real Support GmbH</h2>
            <h2>INVOICE</h2>
          </div>
          <p>Janusz-Korczak-str. 22</p>
          <p>12627, Berlin.</p>
          <p>MwSt­Id : DE319816267</p>
          <p>
            <a href="tel:+030 54811500">030 54811500</a>
          </p>
          <p>
            <a href="mailto:info@kurier247.de">info@kurier247.de</a>
          </p>
          <p>
            <a href="http://www.kurier247.de">www.kurier247.de</a>
          </p>
        </div>
        <div className="invoice-details">
          <div className="invoice-to">
            <h2>To,</h2>
            <h3>{order.ClientId?.InvoiceAddress.BusinessName}</h3>
            <h3>{order.ClientId?.InvoiceAddress.Name}</h3>
            <p>
              {order.ClientId?.InvoiceAddress.Street +
                " " +
                order.ClientId?.InvoiceAddress.HouseNumber}
            </p>
            <p>
              {order.ClientId?.InvoiceAddress.PostCode +
                " " +
                order.ClientId?.InvoiceAddress.City +
                "."}
            </p>
            <p>
              <a href="tel:+{order.ClientId?.Phone}">{order.ClientId?.Phone}</a>
            </p>
            <p>
              <a href="mailto:{order.ClientId?.Email}">
                {order.ClientId?.Email}
              </a>
            </p>
          </div>
          <div className="invoice-info">
            <p>Invoice No: 0{order.InvoiceNumber} </p>
            <p>Invoice Date: {localDate} </p>
            <p>Payment Type: {order.PaymentType} </p>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Package</th>
              <th>Time</th>
              <th>Base Price</th>
              <th>Distance Price</th>
              <th>Loading / Waiting</th>
              <th>Helping</th>
              <th>Stoppage</th>
              <th>SubTotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{order.PackageId?.TitleDE}</td>
              <td>{order.BookingTime}</td>
              <td>{order.PackageId?.PackagePrice} £</td>
              <td>{KmPrice} £</td>
              <td>{loadingPrice} £</td>
              <td>{helpingPrice} £</td>
              <td>{StopagePrice} £</td>
              <td>
                <p>{Netto} £</p>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="7">Netto</td>
              <td>{Netto} £</td>
            </tr>
            <tr>
              <td colSpan="7">VAT 20 %</td>
              <td>{Vat} £</td>
            </tr>
            <tr>
              <td colSpan="7">Total Sum</td>
              <td>{total} £</td>
            </tr>
            <tr>
              <td colSpan="7">Discount / Advanced</td>
              <td>0 £</td>
            </tr>
            <tr>
              <td colSpan="7">
                <h3>Invoice Total</h3>
              </td>
              <td>
                <h3>{total} £</h3>
              </td>
            </tr>
          </tfoot>
        </table>

        <footer>
          {order.PaymentType === "INVOICE" ? (
            <>
              <p>
                Bitte zahlen Sie an (mit Rechnung ­Nr) , Empfänger: Real Support
                GmbH
              </p>
              <h3>IBAN: DE98 1001 0010 0742 3031 22, BIC: PBNKDEFF</h3>
              <h4>
                Zahlungsbedingungen: Die Zahlung ist innerhalb von 7 Werktagen
                nach Rechnungsdatum.
              </h4>
            </>
          ) : (
            <>
              <p>Thank you for your Payment.</p>
              <p>It was nice working with you!</p>
            </>
          )}
        </footer>
      </div>
    );
  }
}
