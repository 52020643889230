import React, { useContext, useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { ProductContext } from "../../context/ProductContext.jsx";
import ReactPaginate from "react-paginate";
import "../../scss/Orders.scss";

const Orders = () => {
  const { bookings } = useContext(ProductContext);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [sortItems, setSortItems] = useState([]);

  // console.log(bookings);
  // console.log(
  //   sortItems.sort((a, b) => {
  //     const x = a.BookingStatus.toLowerCase();
  //     console.log(x);
  //     const y = b.BookingStatus.toLowerCase();
  //     // console.log(y);
  //     if (x < y) {
  //       return -1;
  //     }
  //     if (x > y) {
  //       return 1;
  //     }
  //     return 0;
  //   })
  // );

  // const [sortItems, setSortItems] = useState(
  //   bookings.sort((a, b) => b.OrderNumber - a.OrderNumber)
  // );

  useEffect(() => {
    const filtedItems = [];
    bookings.map((order) => {
      filtedItems.push(order);
      return order;
    });
    setSortItems(
      filtedItems.sort((a, b) => {
        const x = a.BookingStatus.toLowerCase();
        const y = b.BookingStatus.toLowerCase();
        if (x < y) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
        return 0;
      })
    );
  }, [bookings]);

  const handleSort = (data) => {
    const Items = [];
    bookings.map((item) => {
      if (data === "All") {
        Items.push(item);
      }
      if (data === item.BookingStatus) {
        Items.push(item);
      }
      return Items;
    });
    setSortItems(Items);
  };

  // Pagination

  const [pageNumber, setPageNumber] = useState(0);

  const ordersPerPage = 5;
  const pagesVisited = pageNumber * ordersPerPage;
  const pageCount = Math.ceil(sortItems.length / ordersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Total Value

  let baseTotalArrays = [];
  let distTotalArrays = [];
  let orderTotalArrays = [];
  let paidOutArrays = [];
  let grossTotalArrays = [];
  let vatTotalArrays = [];
  let netTotalArrays = [];
  sortItems
    .slice(pagesVisited, pagesVisited + ordersPerPage)
    .filter((item) => {
      if (search === "") {
        return item;
      }
      if (
        item.ClientId.FullName?.toLowerCase().includes(
          search.toLocaleLowerCase()
        )
      ) {
        return item;
      }
      if (
        item.PartnerId?.FullName.toLowerCase().includes(
          search.toLocaleLowerCase()
        )
      ) {
        return item;
      }
      return null;
    })
    .forEach((item) => {
      const basePrice = item.PackageId?.PackagePrice;
      baseTotalArrays.push(basePrice);
      const KmPrice =
        (item.PackageId?.PricePerKm * item.Total_KM).toFixed(2) * 1;
      distTotalArrays.push(KmPrice);
      const StopagePrice =
        (item.PackageId?.StoppagePrice * item.StopagesAddress?.length).toFixed(
          2
        ) * 1;
      const loadingPrice = (item.Load_Unload / 5) * item.PackageId?.Price5min;
      const helpingPrice =
        (item.Load_Unload_Help / 60) * item.PackageId?.PricePerHelper;
      const Netto =
        (
          basePrice +
          KmPrice +
          StopagePrice +
          loadingPrice +
          helpingPrice
        ).toFixed(2) * 1;
      const Vat = (Netto * (item.PackageId?.MwSt / 100)).toFixed(2) * 1;
      const orderTotal = (Netto + Vat).toFixed(2) * 1;
      orderTotalArrays.push(orderTotal);
      const paidOut =
        (orderTotal - orderTotal * (item.PartnerId?.Commission / 100)).toFixed(
          2
        ) * 1;
      paidOutArrays.push(paidOut);
      const grosstoCompany = (orderTotal - paidOut).toFixed(2) * 1;
      grossTotalArrays.push(grosstoCompany);
      const vat = ((grosstoCompany / 119) * 19).toFixed(2) * 1;
      vatTotalArrays.push(vat);
      const net = (grosstoCompany - vat).toFixed(2) * 1;
      netTotalArrays.push(net);
    });

  // const baseSum =
  //   baseTotalArrays
  //     ?.reduce(
  //       (acc, curr) => {
  //         return acc + curr;
  //       },
  //       0,
  //       0
  //     )
  //     .toFixed(2) * 1;
  // const distanceSum =
  //   distTotalArrays
  //     ?.reduce(
  //       (acc, curr) => {
  //         return acc + curr;
  //       },
  //       0,
  //       0
  //     )
  //     .toFixed(2) * 1;
  const totalSum =
    orderTotalArrays
      ?.reduce(
        (acc, curr) => {
          return acc + curr;
        },
        0,
        0
      )
      .toFixed(2) * 1;
  const paidSum =
    paidOutArrays
      ?.reduce((acc, curr) => {
        return acc + curr;
      }, 0)
      .toFixed(2) * 1;
  // const groseSum =
  //   grossTotalArrays
  //     ?.reduce((acc, curr) => {
  //       return acc + curr;
  //     }, 0)
  //     .toFixed(2) * 1;
  // const vatSum =
  //   vatTotalArrays
  //     ?.reduce((acc, curr) => {
  //       return acc + curr;
  //     }, 0)
  //     .toFixed(2) * 1;
  // const netSum =
  //   netTotalArrays
  //     ?.reduce((acc, curr) => {
  //       return acc + curr;
  //     }, 0)
  //     .toFixed(2) * 1;

  return (
    <div className="admin-account-view">
      <div className="view-order">
        <h1>Kurier 247 Account Summery</h1>
        <div className="search-filter">
          <div className="select-date">
            <input type="date" name="from" id="from" />
            <input type="date" name="to" id="to" />
          </div>
          <div className="text-input">
            <input
              type="text"
              value={search}
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <select
              onChange={(e) => {
                handleSort(e.target.value);
              }}
            >
              <option value="All">All</option>
              <option value="Pending">Pending</option>
              <option value="Reject">Reject</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Accomplished">Accomplished</option>
              <option value="Cancel">Cancel</option>
            </select>
          </div>
          <div className="submit-button">
            <button type="submit">Search</button>
            <button type="button">Export</button>
          </div>
        </div>

        <Table>
          <Thead>
            <Tr>
              <Th>Order Id</Th>
              <Th>Order Status</Th>
              <Th>Partner</Th>
              <Th>Client</Th>
              <Th>Package</Th>
              <Th>Base Price</Th>
              <Th>Booking Date</Th>
              <Th>Booking Time</Th>
              <Th>Distance price</Th>
              <Th>Waiting min</Th>
              <Th>Helping min</Th>
              <Th>Stopage</Th>
              <Th>Order Total</Th>
              <Th>Paid to Partner</Th>
              <Th>Payment Type</Th>
              <Th>Payment Status</Th>
              <Th>Details</Th>
            </Tr>
          </Thead>
          {sortItems
            .slice(pagesVisited, pagesVisited + ordersPerPage)
            .filter((item) => {
              if (search === "") {
                return item;
              }
              if (
                item.ClientId.FullName?.toLowerCase().includes(
                  search.toLocaleLowerCase()
                )
              ) {
                return item;
              }
              if (
                item.PartnerId?.FullName.toLowerCase().includes(
                  search.toLocaleLowerCase()
                )
              ) {
                return item;
              }
              return null;
            })
            .map((item) => {
              const basePrice = item.PackageId?.PackagePrice;
              const KmPrice =
                (item.PackageId?.PricePerKm * item.Total_KM).toFixed(2) * 1;
              const StopagePrice =
                (
                  item.PackageId?.StoppagePrice * item.StopagesAddress?.length
                ).toFixed(2) * 1;
              const loadingPrice =
                (item.Load_Unload / 5) * item.PackageId?.Price5min;
              const helpingPrice =
                (item.Load_Unload_Help / 60) * item.PackageId?.PricePerHelper;
              const Netto =
                (
                  basePrice +
                  KmPrice +
                  StopagePrice +
                  loadingPrice +
                  helpingPrice
                ).toFixed(2) * 1;
              const Vat = (Netto * (item.PackageId?.MwSt / 100)).toFixed(2) * 1;
              const orderTotal = (Netto + Vat).toFixed(2) * 1;
              const paidOut =
                (
                  orderTotal -
                  orderTotal * (item.PartnerId?.Commission / 100)
                ).toFixed(2) * 1;
              return (
                <Tbody key={item._id}>
                  <Tr>
                    <Td>{item.OrderNumber}</Td>
                    <Td>{item.BookingStatus}</Td>
                    <Td>{item.PartnerId?.FullName}</Td>
                    <Td>{item.ClientId?.FullName}</Td>
                    <Td>{item.PackageId?.Title}</Td>
                    <Td>{basePrice} £</Td>
                    <Td>{item.BookingDate.slice(0, 10)} </Td>
                    <Td>{item.BookingTime}</Td>
                    <Td>{KmPrice} £</Td>
                    <Td>{item.Load_Unload} Min</Td>
                    <Td>{item.Load_Unload_Help} Min</Td>
                    <Td>{StopagePrice} £</Td>
                    <Td>{orderTotal} £</Td>
                    <Td>{paidOut} £</Td>
                    <Td>{item.PaymentType}</Td>
                    <Td>{item.PaymentStatus}</Td>
                    <Td>
                      <Link to={`/order-details/${item._id}`}>View</Link>
                    </Td>
                  </Tr>
                </Tbody>
              );
            })}
          <tfoot>
            <Tr>
              <Td colSpan="5">Total</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>{totalSum} £</Td>
              <Td>{paidSum} £</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          </tfoot>
        </Table>
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
          breakLabel={"..."}
        />
      </div>
    </div>
  );
};

export default Orders;
